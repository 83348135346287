import React, { useState } from 'react';
import DaybookListModal from './daybooklistmodal';

const DaybookListTable2 = ({ ob,cb,HEAD, TABLE_ROWS, datakey }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-11/12 bg-white mt-10 mr-auto ml-auto border rounded">
        <thead>
          <tr>
            {HEAD.map((head) => (
              <td
                key={head}
                className="border-y border-blue-gray-100 bg-blue-gray-50/50 px-4 py-1 text-gray-600"
              >
                {head}
              </td>
            ))}
          </tr>
        </thead>
        <thead>
            <tr >
                <td colSpan={3} style={{ textAlign: "right" }}>
                    
                
                    
                </td>
                <td style={{ textAlign: "center" , fontWeight: "bold"  }}>
                    Opening Balance
                
                    
                </td>
                <td colSpan={4} style={{ textAlign: "center" , fontWeight: "bold"  }}>
                    {ob}
                </td>
            </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {TABLE_ROWS.map((row, index) => (
            <tr
              key={index}
              className="transition-all cursor-pointer hover:bg-gray-100"
              onClick={() => handleRowClick(row)}
            >
              {datakey.map((key) => (
                <td key={key} className="px-4 py-1 border-b border-blue-gray-50">
                  <div className="text-sm leading-5 text-gray-900">{row[key]}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <thead>
            <tr >
                <td colSpan={3} style={{ textAlign: "right" }}>
                    
                
                    
                </td>
                <td style={{ textAlign: "center" , fontWeight: "bold"  }}>
                    Closing Balance
                
                    
                </td>
                <td colSpan={4} style={{ textAlign: "center" , fontWeight: "bold"  }}>
                    {cb}
                </td>
            </tr>
        </thead>
      </table>
      
      {/* Modal */}
      <DaybookListModal isOpen={isModalOpen} onClose={closeModal} customer={selectedCustomer} />
    </div>
  );
};

export default DaybookListTable2;
