import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Title } from '../../titles/titles';
import Pagination from '../../components/Pagination';
import { AppContext } from '../../context/appContext';
import DaybookListTable from './daybooktable';
import AlertMessage from '../../components/alert/Alert';
import '../customer/customer.css'
import DashBoard from '../../components/DashBoard';
import jsPDF from "jspdf";
import "jspdf-autotable";


function BankTransactions() {
  const today = new Date().toISOString().split("T")[0];
  const datakey = ["trdate", "code", "description", "credit", "debit"];
  const TABLE_HEAD = ["Date", "Code", "Description", "Credit", "Debit"];
  const [startDate, setStartDate] = useState(today);
  const [openbal, setopenbal] = useState(0);
  const [totalCredit, setTotalcredit] = useState(null);
  const [totalDebit, setTotalDebit] = useState(null);
  const [closebal, setclosebal] = useState(0);
  const [endDate, setEndDate] = useState(today);
  const [daybooks, setDaybooks] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTriggered, setSearchTriggered] = useState(false); // Track search state
  const [validationError, setValidationError] = useState(""); // Track validation errors
  const [alertOpen, setAlertOpen] = useState(false); // Manage alert visibility
  const itemsPerPage = 16;
  const {baseurl, comcode, brcode,sidebarToggle } = useContext(AppContext);
  const [hcode, setHcode] = useState("Bank");
  
  const getFinancialYear = (date) => {
    const d = new Date(date);
    let year = d.getFullYear();
    const month = d.getMonth();

    if (month < 3) {
      year -= 1;
    }

    return { start: `${year}-04-01`, end: `${year + 1}-03-31` };
  };

  const handleSearch = async () => {
    if (!startDate || !endDate) {
      setValidationError("Please select both start and end dates.");
      setError("");
      setAlertOpen(true);
      setDaybooks([]);
      return;
    }
    
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (endDateObj < startDateObj) {
      setValidationError("End date cannot be earlier than the start date.");
      setError("");
      setAlertOpen(true);
      setDaybooks([]);
      return;
    }

    const { start: financialStart, end: financialEnd } =
      getFinancialYear(endDate);
    const financialStartDate = new Date(financialStart);
    const financialEndDate = new Date(financialEnd);

    if (startDateObj < financialStartDate || endDateObj > financialEndDate) {
      const alertMessage = `Dates are not in financial year.`;
      setValidationError(alertMessage);
      setError("");
      setAlertOpen(true);
      setDaybooks([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `${baseurl}accounting/get-bank-trans/${comcode}/${brcode}/${hcode}/`,
        {
        
          params: {
            start_date: startDate,
            end_date: endDate,
            page: currentPage,
            type: "view",
          },
        }
      );
      setopenbal(response.data.results.openingbal);
      setclosebal(response.data.results.closingbal);
      setDaybooks(response.data.results.data);
      setTotalItems(response.data.count);
      setTotalcredit(response.data.results.tcredit);
      setTotalDebit(response.data.results.tdebit);
      setError("");
      setValidationError("");
      setAlertOpen(false); // Close alert if data fetch is successful
    } catch (error) {
      console.log(error.response,"=======");
      setError(error.response?.data?.error || "No data Found");
      setDaybooks([]);
      setValidationError("");
      setAlertOpen(true);
    }
    setLoading(false);
  };

  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchButtonClick = () => {
    setSearchTriggered(true);
    handleSearch();
  };

  const handleReset = () => {
    setStartDate(today);
    setEndDate(today);
    setSearchTriggered(false);
    setDaybooks([]);
    setError("");
    setTotalItems(0);
    setCurrentPage(1);
    setValidationError("");
    setAlertOpen(false);
  };

  useEffect(() => {
    if (searchTriggered) {
      handleSearch();
    }
  }, [currentPage, searchTriggered]);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    setCurrentPage(1);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    setCurrentPage(1);
  };
  const fetchAndDownload = async () => {
    if (startDate <= endDate) {
      const response = await axios
        .get(
          `${baseurl}accounting/get-bank-trans/${comcode}/${brcode}/${hcode}/`,
          {
       
            params: {
              start_date: startDate,
              end_date: endDate,
              type: "dwnld",
            },
          }
        )
        .then((response) => {
          const address = response.data.address;
         
          downloadPDF(
            address.cname,
            address.bname,
            address.address,
            address.email,
            address.phone,
            totalCredit,
            totalDebit,
            startDate,
            endDate,
            response.data.data,
            openbal,
            closebal
          );
        })
        .catch((err) => {
          console.log(err);
          alert("Error fetching data.");
        });
    }
  };

  const downloadPDF = (
    cname,
    bname,
    address,
    email,
    phone,
    totalCredit,
    totalDebit,
    formattedStartDate,
    formattedEndDate,
    transactions,
    openingBalance,
    closingBalance
  ) => {
    if (!transactions.length) {
      alert("No data available for download.");
      return;
    }

    const doc = new jsPDF();

    // Formatting Dates for Report Title (without timezone shift)
    const formatDate = (date) => {
      // Assuming the date is already in 'YYYY-MM-DD' format
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    };

    // Add title and period details

    const centerText = (doc, text, y) => {
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextWidth(text);
      const x = (pageWidth - textWidth) / 2;
      doc.text(text, x, y);
    };

    // Add title and period details
    doc.setFontSize(16);
    centerText(doc, cname, 15);
    doc.setFontSize(12);
    centerText(doc, address, 20);
    doc.setFontSize(12);
    centerText(doc, bname, 25);
    const emph = `${email} | ${phone}`;
    centerText(doc, emph, 30);
    doc.setFontSize(14);
    centerText(
      doc,
      `Bank Transactions(${formatDate(formattedStartDate)} To ${formatDate(
        formattedEndDate
      )})`,
      35
    );
    doc.setFontSize(12);
    doc.text(`Opening Balance: ${openingBalance || 0}`, 14, 45);
    doc.text(`Total Credit: ${totalCredit || 0}`, 120, 45);

    doc.text(`Closing Balance: ${closingBalance || 0}`, 14, 50);
    doc.text(`Total Debit: ${totalDebit || 0}`, 120, 50);

    // Prepare table data
    const rows = transactions.map((transaction) =>
      datakey.map((key) => transaction[key] || "")
    );

    // Add table with auto-pagination
    doc.autoTable({
      head: [TABLE_HEAD],
      body: rows,
      startY: 55,
      theme: "striped",
      styles: { fontSize: 10 },
      columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 30 } }, // Customize column widths
      headStyles: {
        fillColor: [0, 0, 0], // Set the background color for the header (black in RGB)
        textColor: [255, 255, 255], // Set the text color for the header (white in RGB)
        fontSize: 12,
        fontStyle: "bold",
      },
      didDrawPage: (data) => {
        // Footer with page numbers

        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber}`,
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        );
      },
    });

    // Save the PDF
    const fileName = `Bank Transactions(${formatDate(
      formattedStartDate
    )} To ${formatDate(formattedEndDate)}).pdf`;
    doc.save(fileName);
  };
  return (
    <div>
      <DashBoard /> 
      <div
        className={`${
          !sidebarToggle ? "ms-64 duration-500" : "ms-0 duration-500"
        }`}
      >
        <div className="pt-20">
          <Title title="Bank Transations" />
          <div className="flex w-11/12 ml-auto mr-auto  text-left flex-wrap gap-x-4">
            <div className="flex items-center">
              <label className="mr-2">Start Date:</label>
              <input
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                className="border rounded px-2 py-1"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-2">End Date:</label>
              <input
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                className="border rounded px-2 py-1"
              />
            </div>
            <button
              onClick={handleSearchButtonClick}
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Search
            </button>
            <button
              onClick={handleReset}
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Reset
            </button>
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
              onClick={fetchAndDownload}
              disabled={daybooks.length === 0}
            >
              Download
            </button>
          </div>
          {loading && <div className="spinner"></div>} {/* loading spinner */}
          <AlertMessage
                    open={alertOpen}
                    setOpen={setAlertOpen}
                    message={validationError || error}
                    color={validationError || error ? 'red' : 'green'}
                />
          {searchTriggered && daybooks.length > 0 && (
            <>
              <DaybookListTable
                ob={openbal}
                cb={closebal}
                TABLE_ROWS={daybooks}
                HEAD={[
                  "Date",
                  "Code",
                  "Name",
                  "Description",
                  "Mode",
                  "Credit",
                  "Debit",
                  "Voucher No",
                  "User Code",
                ]}
                datakey={[
                  "trdate",
                  "code",
                  "name",
                  "description",
                  "mode",
                  "credit",
                  "debit",
                  "voucher_no",
                  "ucode",
                ]}
              />

              <div style={{ textAlign: "center" }}>
                <h4 className="balance" style={{ fontSize: "1.3rem" }}>
                  Total Credit : {totalCredit}
                </h4>
                <h4 className="balance" style={{ fontSize: "1.3rem" }}>
                  Total Debit : {totalDebit}
                </h4>
              </div>

              <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                activePage={currentPage}
                setActivePage={handleSetActivePage}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BankTransactions;