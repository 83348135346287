import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import "./voucherprint.css";
import { AppContext } from "../../context/appContext";
import { useLocation } from "react-router";

function VoucherPrint() {
  const location = useLocation();
  const data = location.state;
  console.log(data);
  const [voucherNo, setVoucherNo] = useState("");
  const [totalCredit, setTotalCredit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searched, setSearched] = useState(false);
  const [daybooks, setDaybooks] = useState(false);
  const [searchSuccessful, setSearchSuccessful] = useState(false);
  const { baseurl, comcode, brcode } = useContext(AppContext);
  const [address, setAddress] = useState();
  const [desc, setDesc] = useState();

  const handleSearch = async (voucherNo) => {
    if (!voucherNo) {
      // alert("Please enter a voucher number");
      return;
    }

    setLoading(true);
    setError(null);
    setSearched(true);
    setSearchSuccessful(false); // Reset search successful state

    try {
      const response = await axios.get(
        `${baseurl}accounting/closing-voucher/${comcode}/${brcode}/${voucherNo}/`
      );
      console.log("API Response:", response.data.address.title); // Log the full response for debugging
      setTotalCredit(response.data.data.total_credit);
      setDaybooks(response.data.data);
      setSearchSuccessful(true); // Mark search as successful
      setAddress(response.data.address);
      setDesc(response.data.address.title)
    } catch (err) {
      console.error("API Error:", err); // Log error for debugging
      setError(err.message);
      setSearchSuccessful(false); // Mark search as unsuccessful
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };
  useEffect(() => {
    data && setVoucherNo(data.voucher_no);
    handleSearch(data.voucher_no);
  }, [data]);
  return (
    <>
      <div
        className="w-full flex justify-center items-start"
        style={{
          fontFamily: "sans-serif",
          paddingTop: "20px",
          minHeight: "100vh",
        }}
      >
        <div
          className="bg-white font-medium"
          style={{
            width: "80%", // Adjust the content width (can be modified to fit your design)
            maxWidth: "800px", // Maximum width of the content
            padding: "20px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Optional: add a shadow for better visual separation
          }}
        >
          <div className="text-center mt-5 no-print">
            <input
              type="text"
              placeholder="Enter Voucher Number"
              value={voucherNo}
              onChange={(e) => setVoucherNo(e.target.value)}
              className="border p-2"
            />
            <button
              onClick={() => handleSearch(voucherNo)}
              className="ml-3 bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
            >
              Search
            </button>
            <button
              onClick={handlePrint}
              className={`ml-3 ${
                searchSuccessful
                  ? "bg-gray-500 hover:bg-gray-700"
                  : "bg-gray-500"
              } text-white px-4 py-2 rounded`}
              disabled={!searchSuccessful}
            >
              Print
            </button>
          </div>

          {searched && (
            <div className="page" id="page3">
              <div className="w-full">
                {loading && <div>Loading...</div>}
                {error && <div>Error: {error}</div>}
                {totalCredit !== null && !loading && !error && (
                  <div className="voucher-content">
                    <div
                      className="mr-auto ml-auto"
                      style={{
                        padding: "20px",
                        backgroundColor: "white",
                        fontFamily: "TimesRoman, Times New Roman, serif",
                      }}
                    >
                      <div className="text-center">
                        <p style={{ fontSize: "25px" }}>{address.cname}</p>
                        <p>{address.address}</p>
                        <p>{address.bname}</p>
                        <p>
                          {address.email} | {address.phone}
                        </p>
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <div className="flex justify-between mt-3">
                        <span>
                          Date:&nbsp;&nbsp;
                          {daybooks.records[0].trdate}
                        </span>
                        <b>
                          <u>Receipt</u>
                        </b>
                        <span>
                          No:&nbsp;&nbsp;
                          {daybooks.records[0].voucher_no}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div className="w-3/4 mr-auto ml-auto flex justify-between mt-10">
                      <span>
                        Received from&nbsp;
                        {daybooks.records[0].name.toUpperCase()}
                        &nbsp;an amount of&nbsp; Rs.&nbsp;
                        {totalCredit.toLocaleString()} &nbsp;by&nbsp;
                        {daybooks.records[0].mode}&nbsp; for {desc} of goldloan
                        No: &nbsp;
                        {daybooks.records[0].code.toUpperCase()}.
                      </span>
                    </div>
                    <div className="w-full flex justify-around mt-10">
                      <span>Signature of Customer</span>
                      <span>For {address.bname.toUpperCase()}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default VoucherPrint;
