import React, { useContext, useEffect, useState } from "react";
// import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import "./login.css";
import { Title } from "../../titles/titles";
import { AppContext } from "../../context/appContext";
import { useNavigate } from "react-router";
import { jwtDecode } from "jwt-decode";
import { Button } from "@material-tailwind/react";
import { Input } from "@material-tailwind/react";

function LoginForm(props) {
  const {
    baseurl,
    setUser,
    setAuthToken,
    setBrcode,
    setUcode,
    setComcode,
    setRefreshToken,
    setPermissions,
    password_reset
  } = useContext(AppContext);
  const navigate = useNavigate();
  const url = `${baseurl}user/api/token/`;
  const [isLoading, setIsLoading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState({})
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [errorPsw, setErrorPsw] = useState("");
  const [errorUsername, setErrorUsername] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    document.title = "Login";
  }, []);

  const classes = "form-control mt-4 block justify-between w-full";
  // const {
  //   register,
  //   setError,
  //   reset,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  const handleChange = (e) => {
    if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "username") {
      setUsername(e.target.value);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (username.length === 0) {
      setErrorUsername("Please enter you employee id");
      setIsLoading(false);

      return;
    } else {
      setErrorUsername("");
    }
    if (password.length === 0) {
      setErrorPsw("Please enter your password");
      setIsLoading(false);
      return;
    } else {
      setErrorPsw("");
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        // credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      const result = await response.json();

      // reset()
      // alert(result.message)
      console.log(response);
      if (response.status === 400) {
        console.log(result.error, response);

        setIsLoading(false);
        // for (const [key, value] of Object.entries(result.error)) {
        //   setError(key, {
        //     type: "server",
        //     message: value,
        //   });
        // }
      } else if (response.status === 200) {
        setUser(jwtDecode(result.access));
        setBrcode(jwtDecode(result.access).brcode);
        setComcode(jwtDecode(result.access).comcode);
        setUcode(jwtDecode(result.access).user_id);
        setPermissions(jwtDecode(result.access).permissions)
        
        setAuthToken(result.access);
        setRefreshToken(result.refresh)
        localStorage.setItem("token", result.access);
        localStorage.setItem("refresh", result.refresh);
        setIsLoading(false);
        //console.log(jwtDecode(result.access),"eeeeeeeeeeeeeeeeeeeeeeee")
        console.log(password_reset,"eeeeeeeeeeeeeeeeeeeeeeee")
        if (password_reset==true){
          navigate("/pwdreset");
        }
        else{
        navigate("/homepage");
      }
      } else if (response.status === 401) {
        setError("Invalid EmployeeId or Password.");
        // setError('password', {
        //   type: "server",
        //   message: 'Invalid EmployeeId or Passord'

        // })
        setIsLoading(false);
      } else if (response.status === 500) {
        setError("Internal Server Error.");
        setIsLoading(false);
      } else {
        setError("Connection Error.");
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Error:", err);
      // Handle error
      if (err.response) {
        // Server responded with a status code other than 2xx
        console.error("Response error:", err.response.status);
        setError(`Server error: ${err.response.status}`);
      } else if (err.request) {
        // Request was made but no response was received
        console.error("Network error:", err.message);
        setError("Network error: Unable to connect to the server.");
      } else {
        // Something else happened while setting up the request
        console.error("Error", err.message);
        setError("Unexpected error occurred.");
      }
      setIsLoading(false);
    }
  };
  return (
    <div className="flex justify-center ">
      <div className="border-2 bg-white p-8 shadow-lg rounded-lg w-full md:w-1/2 custom-width ">
        <Title title="LOGIN" />
        <form onSubmit={handleSubmit}>
          <div className={classes}>
            {/* <label className="block text-gray-700 text-sm font-bold">
              Employee Id
            </label> */}
            <Input
              label="EmployeeId"
              type="text"
              className="h-20 "
              name="username"
              error={errorUsername || error ? true : false}
              onChange={handleChange}
              // errors={errors}
              // register={register}
              // validationSchema={{
              //   required: "This field is required",
              //   minLength: {
              //     value: 3,
              //     message: "Please enter a minimum of 3 characters",
              //   },
              // }}
              required
            />
            <span style={{ color: "red" }}>
              {errorUsername && errorUsername}
            </span>
          </div>
          <div className={classes}>
            {/* <label className="block text-gray-700 text-sm font-bold">
              Password{" "}
            </label> */}
            <Input
              label="Password"
              type="password"
              name="password"
              error={errorPsw || error ? true : false}
              onChange={handleChange}
              // color="pink"
              // errors={errors}
              // register={register}
              // validationSchema={{
              //   required: "This field is required",
              //   minLength: {
              //     value: 3,
              //     message: "Please enter a minimum of 3 characters",
              //   },
              // }}
              required
            />
            <span style={{ color: "red" }}>{errorPsw && errorPsw}</span>
            <span style={{ color: "red" }}>{error && error}</span>
          </div>
          <div className="flex justify-center mt-5">
            <Button
              type="submit"
              size="md"
              className="bg-gray-700 w-full hover:bg-gray-950 text-white  rounded"
              loading={isLoading}
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
