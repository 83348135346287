// LOAN REPAY RIGHT => CALCULATATION

import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import { LoanCloseContext } from "./LoanCloseContext";
import { styles, ClassNames } from "../../styles/style";
import "../login/login.css";

function LoanCloseDivision(props) {
  let {
    loan,
    total1,
    setTotal1,
    finaldc11,
    setFinaldc11,
    finalnotice11,
    setFinalnotice11,
    finalgst11,
    setFinalgst11,
    finalotc11,
    setFinalotc11,
    finalinterest11,
    setFinalinterest11,
    finalamt11,
    setFinalamt11,
    val1,
    setval1,
    runningTotal,
    setRunningTotal
  } = useContext(LoanCloseContext);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleAmount = (e) => {
    let amount = parseFloat(e.target.value);
    let finalAmount = 0;

    if (loan.dc <= amount && amount) {
      amount -= loan.dc;
      finalAmount += loan.dc;
      setValue("finaldc1", loan.dc);
      setFinaldc11(loan.dc);
    } else if (loan.dc > amount && amount) {
      setValue("finaldc1", amount);
      setFinaldc11(amount);

      finalAmount += amount;
      amount -= amount;
    } else {
      setValue("finaldc1", 0);
      setFinaldc11(0);
    }
    if (loan.notice <= amount && amount) {
      amount -= loan.notice;
      finalAmount += loan.notice;
      setValue("finalnotice1", loan.notice);
      setFinalnotice11(loan.notice);
    } else if (loan.notice > amount && amount) {
      setValue("finalnotice1", amount);
      setFinalnotice11(amount);
      finalAmount += amount;
      amount -= amount;
    } else {
      setValue("finalnotice1", 0);
      setFinalnotice11(0);
    }

    if (loan.gst <= amount && amount) {
      setValue("finalgst", loan.gst);
      setFinalgst11(loan.gst);

      finalAmount += loan.gst;
      amount -= loan.gst;
    } else if (loan.gst > amount) {
      setValue("finalgst", amount);
      setFinalgst11(amount);

      finalAmount += amount;
      amount -= amount;
    } else {
      setValue("finalgst", 0);
      setFinalgst11(0);
    }

    if (loan.otc <= amount && amount) {
      setValue("finalotc", loan.otc);

      setFinalotc11(loan.otc);
      finalAmount += loan.otc;
      amount -= loan.otc;
    } else if (loan.otc > amount && amount) {
      setValue("finalotc", amount);

      setFinalotc11(amount);
      finalAmount += amount;
      amount -= amount;
    } else {
      setValue("finalotc", 0);
      setFinalotc11(0);
    }

    if (loan.interest <= amount && amount) {
      setValue("finalInterest", loan.interest);

      setFinalinterest11(loan.interest);
      finalAmount += loan.interest;
      amount -= loan.interest;
    } else if (loan.interest > amount && amount) {
      setValue("finalInterest", amount);

      setFinalinterest11(amount);

      finalAmount += amount;
      amount -= amount;
    } else {
      setValue("finalInterest", 0);
      setFinalinterest11(0);
    }
    if ((loan.amount === amount || loan.amount > amount) && amount) {
      setValue("finalamount", amount);

      setFinalamt11(amount);
      finalAmount += amount;
      amount -= loan.amount;
    } else if (loan.amount < amount && amount) {
      amount -= loan.amount;
      finalAmount += loan.amount;
      setValue("finalamount", loan.amount);

      setFinalamt11(loan.amount);
    } else {
      setValue("finalamount", 0);
      setFinalamt11(0);
    }
    setValue("finalAmnt", finalAmount);
    setTotal1(finalAmount);

    // setTotal1(finalAmount)
    //console.log('finalAmnt', amount);
    //console.log('finalAmnt', finalAmount);
  };

  const classes = "form-control mt-1 flex lg:flex-row flex-col justify-between";

  const onSubmit = (data) => {
    console.log(data);
    // reset();
  };

  return (
    <div>
      <form
        className="border md:mt-0 mt-4 shadow-2xl p-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={classes}>
          <label className="block md:text-gray-700 text-lime-500 text-sm">
            Amount
          </label>
          <input
            // classnames={ClassNames.InputFieldTextGreen}
            className="text-right pr-2"
            type="text"
            name="finalamount"
            value={finalamt11 && finalamt11}
          />
        </div>
        <div className={classes}>
          <label className="block md:text-gray-700 text-lime-500 text-sm me-2">
            Interest
          </label>
          <input
            // classnames={ClassNames.InputFieldTextGreen}
            className="text-right pr-2"
            type="text"
            name="finalInterest"
            value={finalinterest11 && finalinterest11}
          />
        </div>
        <div className={classes}>
          <label className="block md:text-gray-700 text-lime-500 text-sm me-2">
            Penal Charges
          </label>
          <input
            // classnames={ClassNames.InputFieldTextGreen}
            className="text-right pr-2"
            type="text"
            name="finalotc"
            value={finalotc11 && finalotc11}
          />
        </div>
        <div className={classes}>
          <label className="block md:text-gray-700 text-lime-500 text-sm  me-2">
            GST
          </label>
          <input
            // classnames={ClassNames.InputFieldTextGreen}
            className="text-right pr-2"
            type="text"
            name="finalgst"
            value={finalgst11 && finalgst11}
          />
        </div>
        <div className={classes}>
          <label className="block md:text-gray-700 text-lime-500 text-sm">
            Notice Charges and Other Charges
          </label>
          <input
            // classnames={ClassNames.InputFieldTextGreen}
            className="text-right pr-2"
            type="text"
            name="finalnotice1"
            value={finalnotice11 && finalnotice11}
          />
        </div>
        <div className={classes}>
          <label className="block md:text-gray-700 text-lime-500 text-sm me-2">
            Documentation Charges
          </label>
          <input
            // classnames={ClassNames.InputFieldTextGreen}
            className="text-right pr-2"
            type="text"
            name="finaldc1"
            value={finaldc11 && finaldc11}
          />
        </div>
        <div className={classes}>
          <label className="block md:text-black text-lime-500 text-sm font-bold me-2">
            Total
          </label>

          <input
            // classnames={ClassNames.InputFieldTextGreen}
            className="text-right pr-2 font-bold "
            type="text"
            name="finalamount"
            value={total1 && total1}
          />
        </div>
      </form>
      <input
        type="text"
        name="amnt"
        value = {runningTotal && runningTotal }
        label=""
        className="text=3xl h-8 text-right pr-2 text-black arrow_none w-full"
        // onChange={(e) => {
        //   handleAmount(e);
        // }}
      />
    </div>
  );
}

export default LoanCloseDivision;
