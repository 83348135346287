import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Modal from "./goldmodal"; // Assuming the Modal component file is in the same directory
import { AppContext } from "../../context/appContext";
import DashBoard from "../../components/DashBoard";
import jsPDF from "jspdf";
import "jspdf-autotable";

const GltrDetails = () => {
  const [goldTrans, setGoldTrans] = useState([]);
  const [selectedGoldLoan, setSelectedGoldLoan] = useState(null);
     const { baseurl, comcode, brcode } = useContext(AppContext);
  const [totalItems, setTotalItems] = useState(0);
  const [error, setError] = useState("");
  const [loanid, setLoanId] = useState("");
  const [loading, setLoading] = useState(false);
  const HEAD = ["Date", "Description", "Credit", "Debit", "Mode"];
  const datakey = ["trdate", "description", "credit", "debit", "mode"];
  

  const fetchTransactions = async () => {
    let lid = document.getElementById("loanid").value;
    if (lid == "") {
      setError("Enter Loan ID");
      setGoldTrans([])
    } else {
      setError("");

      setLoanId(lid);
      setLoading(true);
      try {
         const response = await axios.get(`${baseurl}goldloan/gltrdetails/${comcode}/${brcode}/`,{
        
            params: {
              loanid: lid,
            },
          }
        );

        setGoldTrans(response.data.data); // Assuming your API response structure
        console.log(response);

        setTotalItems(response.data.count);
      } catch (error) {
        console.log("Error fetching data: ", error);
        if (error.response.status === 404) {
          console.log(error.response.data.error);
          setError(error.response.data.error);
        }
      }
      setLoading(false);
    }
  };

  const getCurrentDate = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    return currentDate;
  };

  const openModal = (goldLoan) => {
    setSelectedGoldLoan(goldLoan);
  };

  const closeModal = () => {
    setSelectedGoldLoan(null);
  };
  //   const handleSetActivePage = (pageNumber) => {
  //     setCurrentPage(pageNumber);
  //   };
  const fetchAndDownload = async () => {
    if (loanid) {
        const response = await axios.get(`${baseurl}goldloan/gltrdetails/${comcode}/${brcode}`,
            {
     
            params: {
              loanid: loanid,
            },
          }
        )
        .then((response) => {
          // Set the fetched data
          const address = response.data.address;
          console.log(address);

          // Call downloadPDF after the data is fetched
          downloadPDF(
            response.data.data,
            address.cname,
            address.bname,
            address.address,
            address.email,
            address.phone
          );
        })
        .catch((err) => {
          console.log(err);
          alert("Error fetching data.");
        });
    }
  };

  const downloadPDF = (goldTrans, cname, bname, address, email, phone) => {
    if (!goldTrans.length) {
      alert("No data available for download.");
      return;
    }

    const doc = new jsPDF();

    const centerText = (doc, text, y) => {
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextWidth(text);
      const x = (pageWidth - textWidth) / 2;
      doc.text(text, x, y);
    };

    // Add title and period details
    doc.setFontSize(17);
    centerText(doc, cname, 15);
    doc.setFontSize(10);
    centerText(doc, address, 20);
    doc.setFontSize(10);
    centerText(doc, bname, 25);
    const emph = `${email} | ${phone}`;
    centerText(doc, emph, 30);
    doc.setFontSize(14);
    centerText(doc, `Gold Loan Transactions (${loanid})`, 35);
    // Prepare table data
    const rows = goldTrans.map((trans) =>
      datakey.map((key) => trans[key] || "")
    );

    // Add table with auto-pagination
    doc.autoTable({
      head: [HEAD],
      body: rows,
      startY: 40,
      theme: "striped",
      styles: { fontSize: 10 },
      columnStyles: {
        0: { cellWidth: 30 }, // Date column
        1: { cellWidth: 70 }, // Description column (wider)
        2: { cellWidth: 30 }, // Credit column
        3: { cellWidth: 30 }, // Debit column
        4: { cellWidth: 30 }, // Mode column
      },
      headStyles: {
        fillColor: [0, 0, 0], // Set the background color for the header (black in RGB)
        textColor: [255, 255, 255], // Set the text color for the header (white in RGB)
        fontSize: 12,
        fontStyle: "bold",
      },
      didDrawPage: (data) => {
        // Footer with page numbers
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber}`,
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        );
      },
    });

    // Save the PDF
    const fileName = `Gold Loan Transactions(${loanid}).pdf`;
    doc.save(fileName);
  };

  return (
    <>
      <DashBoard />
      <div className="container mx-auto px-4 py-8 pt-20">
        <h2 className="text-2xl font-bold mb-4 text-center">
          Gold Loan Transactions
        </h2>

        <div className="overflow-x-auto">
          <div className="overflow-y-auto">
            <div className="flex gap-2">
              <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
                <label className="mb-1 text-sm">Loan ID:</label>
                <input
                  type="text"
                  id="loanid"
                  className="border rounded px-2 border-black w-full"
                  required
                />
              </div>
              <div className="flex flex-wrap w-full mt-5">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    fetchTransactions();
                  }}
                  type="submit"
                  className="bg-gray-500 text-white px-4 py-1 h-8 rounded-md hover:bg-gray-600 w-full sm:w-auto"
                >
                  Submit
                </button>
                &nbsp;
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-1 h-8 rounded-md hover:bg-gray-600 w-full sm:w-auto"
                  onClick={fetchAndDownload}
                  disabled={goldTrans.length === 0}
                >
                  Download
                </button>
              </div>
            </div>
            {loading && <div className="spinner"></div>} {/* loading spinner */}
            <table className="min-w-full bg-white border-gray-200 shadow-sm rounded-lg overflow-hidden">
              <thead className="bg-gray-100">
                <tr>
                  <td className="px-4 py-1 text-left">Date</td>
                  <td className="px-4 py-1 text-left">Description</td>
                  <td className="px-4 py-1 text-left">Credit</td>
                  <td className="px-4 py-1 text-left">Debit</td>
                  <td className="px-4 py-1 text-left">Mode</td>
                </tr>
              </thead>
              <p>{error}</p>
              <tbody>
                {goldTrans.map((trans, index) => (
                  <tr
                    key={index}
                    className="text-sm border-b border-gray-200 cursor-pointer hover:bg-gray-50"
                    onClick={() => openModal(trans)}
                  >
                    <td className="px-4 ">{trans.trdate}</td>
                    <td className="px-4 ">{trans.description}</td>
                    <td className="px-4 ">{trans.credit}</td>
                    <td className="px-4 ">{trans.debit}</td>
                    <td className="px-4 ">{trans.mode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default GltrDetails;