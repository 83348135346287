import React, { useState } from "react";
import AlertMessage from "../alert/Alert";

const getOptionClass = (index, length) => {
  let className = "hover:bg-gray-500 border px-4 py-2";
  if (index == 0) className += "rounded-t-lg";
  else if (index == length - 1) className += "rounded-b-lg";
  return className;
};
function AutoComplete({
  options,
  text,
  setText,
  handleChange,
  setShowOption,
  showOption,
  setCustomerDetail,
  setFormData,
  formData,
  setTempCusDetail,
  setCheckBox,
}) {
  const select = (option) => {
    let aadhaar = option["aadhaar"];
    if (aadhaar.length == 12) {
      setText("");
      setFormData({ ...formData, goldloan: { ...formData.goldloan } });
      setCustomerDetail((prev) => ({ ...prev, ...option }));
      setTempCusDetail((prev) => ({ ...prev, ...option }));
      setCheckBox(option.aadhaarverified);
      setShowOption(false);
    } else {
      setError("Invalid Aadhar");
      setOpen(true);
    }
  };
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  return (
    <div className="relative w-56">
      {open && error && (
        <AlertMessage
          color="red"
          message={error}
          open={open}
          setOpen={setOpen}
        />
      )}
      <input
        type="text"
        placeholder="Search"
        value={text}
        onChange={handleChange}
        className="border-2 border-gray-300 w-full focus:border-gray-400 rounded px-4 outline-none"
      />
      {showOption && (
        <ul className="absolute hover:shadow-xl shadow-lg bg-gray-100 w-full h-56 overflow-scroll rounded-lg">
          {options &&
            options.map((option, index) => (
              <li
                key={`e${index}`}
                className={getOptionClass(index, options.length)}
                onClick={(e) => select(option)}
              >
                {option.full_name}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

export default AutoComplete;
