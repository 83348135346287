import { CheckCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Button } from "@material-tailwind/react";
import { useNavigate, useLocation } from "react-router-dom";
import CopyText from "../TextCopy/textCopy";

const SuccessModal2 = ({ isOpen, onClose, message, redirect, reload, data }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  // const formatDate = (dateTimeString) => {
  //     const date = new Date(dateTimeString);
  //     return date.toLocaleDateString(); // This will format date in user's locale

  // };

  return (
    <div className=" fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div className="relative w-1/2 bg-white p-8 rounded-lg mt-20 mb-20">
        {/* <button className="absolute top-5 right-5 text-right text-black-900" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>

                </button> */}
        <div className="flex justify-center items-center">
          <CheckCircleIcon className="h-20 w-20  text-green-500" />
        </div>
        <h6 className="text-xl font-bold mb-4 text-center">Success!</h6>
        <div className="overflow-y-auto borde w-full flex justify-center">
          {message}
          {data && <CopyText textToCopy={data} />}
        </div>
        <div className="overflow-y-auto mt-5 w-full flex justify-center items-center">
          <Button
            size="sm"
            className="bg-green-500"
            onClick={() => {
              onClose();
              // redirect &&  navigate(`${redirect}`);
              //     reload && window.location.reload(); // Reload the page after submission
              navigate("/goldloan");
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal2;
