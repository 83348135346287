
import axios from "axios";
import React, { useEffect, useState,useContext } from "react";
import { TransactionsTable } from "../../components/transtable/TransactionTable";
import { Button } from "@material-tailwind/react";
import { AppContext } from "../../context/appContext";

function Transactions() {
    let { baseurl ,sidebarToggle} = useContext(AppContext)
  const TABLE_HEAD = ["Id", "Date", "Credit", "Debit", "Mode"];
  const datakey = ["id", "trdate", "credit", "debit", "mode"];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [dailyOBalance, setDailyOBalance] = useState(null);
  const [dailyCBalance, setDailyCBalance] = useState(null);

  
  const getFinancialYearEndDate = (date) => {
    const year = date.getMonth() >= 3 ? date.getFullYear() + 1 : date.getFullYear();
    return new Date(year, 2, 31);
  };

  const searchTransactions = () => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const financialYearStart = new Date(end.getFullYear() - (end.getMonth() < 3 ? 1 : 0), 3, 1);

      if (start < financialYearStart) {
        alert("Check if both dates are in the same financial year.");
        return;
      }
      if (start <= end) {
        const formattedStartDate = start.toISOString().split("T")[0];
        const formattedEndDate = end.toISOString().split("T")[0];

        axios
          .get(`${baseurl}accounting/view_daybook/?start=${formattedStartDate}&end=${formattedEndDate}`)
          .then((res) => {
            setFilteredDetails(res.data.transactions);
            setDailyOBalance(res.data.opening_balance);
            setDailyCBalance(res.data.closing_balance);
          })
          .catch(console.log);
      } else {
        alert("Choose Periodical Dates");
      }
    } else {
      alert("Choose Date");
    }
  };

  return (
    <div className="container">
      <div className="inner-container">
        {/* DatePicker Section */}
        <form onSubmit={(e) => e.preventDefault()} className="flex gap-2">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            max={getFinancialYearEndDate(new Date()).toISOString().split("T")[0]}
            className="border rounded px-2 border-black w-40" // Adjust width to normal size
            required
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            min={startDate}
            max={startDate ? getFinancialYearEndDate(new Date(startDate)).toISOString().split("T")[0] : null}
            className="border rounded px-2 border-black w-40" // Adjust width to normal size
            required
          />
          <Button
            type="button"
            className="search-button"
            onClick={searchTransactions}
          >
            Search
          </Button>
        </form>

        {/* Opening Balance Section */}
        <div className="flex justify-between items-center my-4">
          <h6 className="balance text-xl">Opening Balance</h6>
          <h3 className="balance text-xl">₹ {dailyOBalance}</h3>
        </div>

        <TransactionsTable
          TABLE_HEAD={TABLE_HEAD}
          TABLE_ROWS={filteredDetails}
          datakey={datakey}
        />
      </div>

      {/* Closing Balance Section */}
      <div className="flex justify-between items-center mt-4">
        <h6 className="balance text-xl">Closing Balance</h6>
        <h3 className="balance text-xl">₹ {dailyCBalance}</h3>
      </div>
    </div>
  );
}

export default Transactions;
