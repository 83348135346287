import React, { useState } from "react";
import LoanDetailModal from "./LoanDetailModal"; // Adjust the path based on your actual file structure

const LoanDetailTable = ({
  formData,
  customerDetail,
  cities,
  states,
  countries,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
              Customer Id
            </th>
            <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
              Customer Name
            </th>
            <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
              Loan Amount
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr
            className="transition-all cursor-pointer hover:bg-gray-100"
            onClick={() => handleRowClick(0)}
          >
            <td className="px-6 py-4 whitespace-no-wrap">
              <div className="text-sm leading-5 text-gray-900">
                {customerDetail.cusid}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap">
              <div className="text-sm leading-5 text-gray-900">
                {customerDetail.full_name}
              </div>
            </td>

            <td className="px-6 py-4 whitespace-no-wrap">
              <div className="text-sm leading-5 text-gray-900">
                {formData.goldloan.amount}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Modal */}
      <LoanDetailModal
        isOpen={isModalOpen}
        onClose={closeModal}
        formData={formData}
        customerDetail={customerDetail}
        cities={cities}
        states={states}
        countries={countries}
      />
    </div>
  );
};

export default LoanDetailTable;
