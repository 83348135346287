// GOLDLOAN PAGE = > STEP NUMBER 2

import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SimpleTable from "../../components/table/SimpleTable";
import { LoanContext } from "../../context/loanContext";
import { AppContext } from "../../context/appContext";
import { PlusIcon } from "@heroicons/react/24/outline";
import { addDays } from "../../utils/datetime";
import axios from "axios";
import "./goldloan.css";
import AlertMessage from "../../components/alert/Alert";

function GoldLoanApp({ step }) {
  let { formData, setFormData, scheme, mode, goldrate, customerDetail } =
    useContext(LoanContext); // form data
  const { baseurl, comcode, brcode } = useContext(AppContext);

  let [bank, setBank] = useState();

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  let [amnt, setAmnt] = useState({}); // amount details
  const column = ["MODE", "AMOUNT", "BANK", "REF", "ACTION"]; // table head
  const datakey = ["mode", "amount", "bank", "ref"]; // amount details key
  const todayDate = new Date().toISOString().split("T")[0]; // current date

  const [panError, setPanError] = useState(""); // show pancard error message

  // cash balance
  let [tempCashBal, setTempCashBal] = useState(
    formData.goldloan.cashbal && parseFloat(formData.goldloan.cashbal)
  );

  const classes =
    "form-control mt-1 flex justify-between ps-2 w-full md:w-1/2 flex-col";
  const section_class = "flex justify-between flex-col md:flex-row";

  // loan amount error
  let [amountError, setAmountError] = useState({
    bank: "",
    ref: "",
    amount: "",
    mode: "",
  });

  let [schemeDetail, setSchemeDetail] = useState({
    period: "",
    interest_rate: "",
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // amount details onchange
  const handleModeChange = (e) => {
    setAmnt({ ...amnt, [e.target.name]: e.target.value });
    if (e.target.name === "amount" && e.target.value) {
      setAmountError({ ...amountError, amount: "" });
    }
    if (e.target.name === "ref" && e.target.value) {
      setAmountError({ ...amountError, ref: "" });
    }
    if (e.target.name === "bank" && e.target.value) {
      setAmountError({ ...amountError, bank: "" });
    }
    if (e.target.name === "account_no" && e.target.value) {
      setAmountError({ ...amountError, account_no: "" });
    }
  };

  let tempAmount = formData.goldloan.amount;

  // form data onchange
  const handleChange = (e) => {
    if (
      e.target.name === "amount" &&
      e.target.value
      // &&
      // parseFloat(e.target.value) >= 50000 &&
      // formData.goldloan.pan === ""
    ) {
      //   setPanError("Pan Card is required.");
      // }
      setFormData({
        ...formData,
        goldloan: { ...formData.goldloan, [e.target.name]: e.target.value },
      });
    }
  };

  // add amount details
  const addAmount = () => {
    const response = axios
      .get(`${baseurl}goldloan/checkoutstanding/${comcode}/${brcode}`, {
        params: {
          cusid: customerDetail.cusid,
          amount: formData.goldloan.amount,
        },
      })
      .then((response) => {
        console.log(response);
        const status = response.data.status;
        console.log("77777777777777", status);
        let pan = customerDetail.pan;
        if (status == "False" && !pan) {
          
            setError("Verify PAN To Proceed");
            setOpen(true);
          
          
        } else {
          if (!amnt.mode) {
            setAmountError({ ...amountError, mode: "This field is required" });
            return;
          } else {
            setAmountError({ ...amountError, mode: "" });
          }

          if (!amnt.amount) {
            setAmountError({
              ...amountError,
              amount: "This field is required",
            });
            return;
          } else {
            setAmountError({ ...amountError, amount: "" });
          }

          if (amnt.mode.toLowerCase() !== "cash") {
            if (!amnt.ref || !amnt.bank) {
              if (!amnt.ref) {
                setAmountError({
                  ...amountError,
                  ref: "This field is required",
                });
                return;
              }

              if (!amnt.bank) {
                setAmountError({
                  ...amountError,
                  bank: "This field is required",
                });
                return;
              }
              //  if (!amnt.account_no) {
              //   setAmountError({ ...amountError, account_no: "This field is required" });
              //   return;
              // }
            } else {
              // if (amnt.ref) {
              //   delete amnt.ref;
              // }

              // if (amnt.bank) {
              //   delete amnt.bank;
              // }

              let error = {
                ref: "",
                bank: "",
                account_no: "",
              };
              setAmountError(error);
            }
          }

          if (formData.amount.length > 0) {
            // total amount entered
            let temp = formData.amount.reduce((accumulator, currentValue) => {
              return accumulator + parseFloat(currentValue.amount);
            }, 0);

            tempAmount = formData.goldloan.amount - temp;
          }

          if (tempAmount >= parseFloat(amnt.amount)) {
            if (amnt.amount && amnt.mode) {
              if (amnt.mode.toLowerCase() === "cash") {
                if (tempCashBal < parseFloat(amnt.amount)) {
                  setAmountError({
                    ...amountError,
                    amount: "You dont have enough cash balance.",
                  });
                  return;
                } else {
                  setAmountError({ ...amountError, amount: "" });
                  setTempCashBal((prev) => prev - amnt.amount);
                }
              }

              setFormData((prev) => ({
                ...prev,
                amount: [...prev.amount, amnt],
              }));

              setAmnt({ mode: "", amount: "", bank: "", ref: "" });
            }
          } else {
            setAmountError({
              ...amountError,
              amount: "Enter amount is greater than Loan amount",
            });
          }
        }
      });
  };

  // for choose mode
  function handleChangeMode(e) {
    setAmnt({ ...amnt, [e.target.name]: e.target.value });

    if (e.target.name === "mode" && e.target.value) {
      setAmountError({ ...amountError, mode: "" }); // set mode error into none
    }
  }

  // for set scheme details in formdata
  function handleChangeScheme(e) {
    setFormData({
      ...formData,
      goldloan: { ...formData.goldloan, [e.target.name]: e.target.value },
    });

    // select scheme from schemes
    let selectOpt = scheme.find(({ id }) => id === parseInt(e.target.value));
    setSchemeDetail(selectOpt); // set scheme details in state
  }

  useEffect(() => {
    // set formdata when minimun period of scheme details is not none
    schemeDetail.minimum_period &&
      setFormData({
        ...formData,
        goldloan: {
          ...formData.goldloan,
          duedate: new Date(
            addDays(todayDate, parseInt(schemeDetail.scheme_duration)) // add scheme duration with today's date
          )
            .toISOString()
            .split("T")[0],
          period: schemeDetail.scheme_duration,
          interest_rate: schemeDetail.penal_intrest,
          gold_rate: goldrate,
        },
      });

    // fetching bank name
    axios.get(`${baseurl}predbvalue/addbank/`).then((response) => {
      setBank(response.data.data);
    });
  }, [schemeDetail.minimum_period]); // trigger useeffect when minimum period of scheme details is not none

  // function for delete row
  const deleteEle = (i) => {
    const ele =
      formData.amount && formData.amount.filter((item, index) => i !== index);
    setTempCashBal((prev) => prev + parseFloat(formData.amount[i].amount));

    setFormData((prev) => ({
      ...prev,
      amount: ele,
    }));
  };

  return (
    <div className="px-2 border mt-10">
      {open && error && (
        <AlertMessage
          color="red"
          message={error}
          open={open}
          setOpen={setOpen}
        />
      )}
      {console.log(formData)}
      <section className={section_class}>
        <div className={classes}>
          <label className="ml-2">Scheme</label>

          <select
            id="sal"
            name="scheme"
            value={formData.goldloan && formData.goldloan.scheme}
            onChange={handleChangeScheme}
            label=""
            className="w-full h-7 border mt-1 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            defaultValue="Choose"
          >
            <option value="">Choose a scheme</option>

            {scheme &&
              scheme.map((data, index) => {
                return (
                  <option key={index} value={data.id}>
                    {data.scheme_name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className={classes}>
          <label>Period(In Days)</label>
          <input
            type="number"
            name="period"
            value={
              (schemeDetail.scheme_duration && schemeDetail.scheme_duration) ||
              (formData.goldloan && formData.goldloan.period)
            }
            readOnly
            className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
        </div>

        {/* currently this field is not neccessary */}
        {/* <div className={classes}>
          <label>Interest Rate</label>
          <input
            type="text"
            name="interest_rate"
            readOnly
            className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
        </div> */}
        {/* ******************* */}

        <div className={classes}>
          <label>Gold Rate</label>
          <input
            type="text"
            className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            name="gold_rate"
            value={goldrate}
            required
            readOnly
          />
        </div>
      </section>

      <section className={section_class}>
        <div className={classes} style={{ maxHeight: "26px" }}>
          <label>Cash Balance</label>
          <input
            type="text"
            className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            name="balamnt"
            value={formData.goldloan.cashbal}
            required
            readOnly
          />
        </div>
        <div className={classes} style={{ maxHeight: "26px" }}>
          <label>Loan Amount</label>
          <input
            type="text"
            className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            name="amount"
            value={formData.goldloan.amount}
            onChange={handleChange}
            required
          />
          {panError && (
            <span className="text-red-500 text-xs" size="sm">
              {panError}
            </span>
          )}
        </div>
        <div className={classes}>
          <label>Loan Date</label>

          <input
            type="date"
            name="trdate"
            defaultValue={todayDate}
            // max={date}
            // value={date}
            readOnly
            onChange={handleChange}
            className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
        </div>
        <div className={classes}>
          <label>Due Date</label>
          <input
            type="date"
            name="duedate"
            value={formData.goldloan.duedate}
            readOnly
            onChange={handleChange}
            className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
        </div>
      </section>
      <section className={section_class}>
        <div className="w-full md:w-1/3 px-2">
          <label className="ml-2">Mode</label>

          <select
            value={amnt.mode}
            onChange={handleChangeMode}
            // {...register("mode", { required: true })}
            id="sal"
            name="mode"
            label="Mode"
            className="w-full h-7 border mt-1 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            defaultValue="Choose"
            // register={register}
          >
            <option value="">Select Mode</option>
            {mode &&
              mode.map((data, index) => {
                return (
                  <option key={index} value={data.payment_name}>
                    {data.payment_name}
                  </option>
                );
              })}
          </select>
          {amountError.mode && (
            <span className="text-red-500 text-xs" size="sm">
              {amountError.mode}
            </span>
          )}
        </div>
        <div className={classes} style={{ maxHeight: "26px" }}>
          <label>Amount</label>
          <input
            type="number"
            name="amount"
            value={amnt.amount}
            onChange={handleModeChange}
            className="form-control no-spinner text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
          {amountError && (
            <span className="text-red-500 text-xs" size="sm">
              {amountError.amount}
            </span>
          )}
        </div>
        {amnt.mode && amnt.mode.toLowerCase() !== "cash" ? (
          <>
            <div className={classes} style={{ maxHeight: "26px" }}>
              <label>Bank</label>

              <select
                value={amnt.bank ? amnt.bank : ""}
                onChange={handleModeChange}
                id="sal"
                name="bank"
                label=""
                className={`w-full h-7 border mt-1 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none`}
                defaultValue="Choose"
              >
                <option value="">Select Bank</option>

                {bank &&
                  bank.map((data, index) => {
                    return (
                      <option key={index} value={data.bank_name}>
                        {data.bank_name}
                      </option>
                    );
                  })}
              </select>
              {amountError && (
                <span className="text-red-500 text-xs" size="sm">
                  {amountError.bank}
                </span>
              )}
            </div>{" "}
            <div className={classes} style={{ maxHeight: "26px" }}>
              <label>Acc No / UPI Id</label>
              <input
                type="text"
                name="account_no"
                value={amnt.account_no ? amnt.account_no : ""}
                onChange={handleModeChange}
                className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                required
              />
              {/* {amountError && (
                <span className="text-red-500 text-xs" size="sm">
                  {amountError.ref}
                </span>
              )} */}
            </div>
            <div className={classes} style={{ maxHeight: "26px" }}>
              <label>Ref No / IFSC</label>
              <input
                type="text"
                name="ref"
                value={amnt.ref ? amnt.ref : ""}
                onChange={handleModeChange}
                className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                required
              />
              {amountError && (
                <span className="text-red-500 text-xs" size="sm">
                  {amountError.ref}
                </span>
              )}
            </div>
          </>
        ) : (
          ""
        )}

        {/* plus button */}
        <div className={classes}>
          <PlusIcon
            onClick={addAmount}
            className="h-7 w-7 border mt-5 border-lime-500 text-green-500"
          />
        </div>
        {/* end */}
      </section>
      {formData.amount && console.log(formData.amount)}
      {/* Amount Details */}
      <SimpleTable
        columns={column}
        data={formData.amount && formData.amount}
        datakey={datakey}
        height="h-44"
        deleteElement={true}
        setArray={setFormData}
        deleteEle={deleteEle}
      />
      {/* end */}
    </div>
  );
}

export default GoldLoanApp;
