import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Modal from "./goldmodal"; // Assuming the Modal component file is in the same directory
import { AppContext } from "../../context/appContext";
import DashBoard from "../../components/DashBoard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Pagination from "../../components/Pagination";

const GlReport = () => {
  const today = new Date().toISOString().split("T")[0];
  const [goldLoans, setGoldLoans] = useState([]);
  const [selectedGoldLoan, setSelectedGoldLoan] = useState(null);
  const { baseurl, comcode, brcode } = useContext(AppContext);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");
  const itemsPerPage = 16;
  const [status, setStatus] = useState("N");
  const [endDate, setEndDate] = useState(today);
  const [startDate, setStartDate] = useState(today);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [loading, setLoading] = useState(false);
  const TABLE_HEAD = [
    "Loan ID",
    "Name",
    "Amount",
    "Loan Date",
    "Due Date",
    // "Gold Rate",
    "Customer ID",
  ];
  const datakey = [
    "loanid",
    "full_name",
    "amount",
    "trdate",
    "duedate",
    // "gold_rate",
    "customerid",
  ];
  

  const fetchGoldLoans = async (currentPage) => {
    setDate1(startDate);
    setDate2(endDate);
    setLoading(true);
    try {
       const response = await axios.get(`${baseurl}goldloan/get-goldloanlist/${comcode}/${brcode}/`,{
    //   const response = await axios.post(
    //     `http://localhost:8000/goldloans/${comcode}/${brcode}/`,
    //     {},{
          params: {
            page: currentPage,
            status: status,
            start_date: startDate,
            end_date: endDate,
            type: "view",
          },
        }
      );
      

      setGoldLoans(response.data.results.data); // Assuming your API response structure
      console.log(response);

      setTotalItems(response.data.count);
    } catch (error) {
      console.log("Error fetching data: ", error.response.data.errors);
      if (error.response.status === 404) {
        console.log(error.response.data.errors,"=========");
        setError(error.response?.data?.error || 'No data Found');
      }
    }
    setLoading(false);
  };

  const getCurrentDate = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    return currentDate;
  };

  const openModal = (goldLoan) => {
    setSelectedGoldLoan(goldLoan);
  };

  const closeModal = () => {
    setSelectedGoldLoan(null);
  };
  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const fetchAndDownload = async () => {
    if (date1 && date2) {
        const response = await axios.get(`${baseurl}goldloan/get-goldloanlist/${comcode}/${brcode}`,
             {
    //   const response = await axios
    //     .post(`http://localhost:8000/goldloans/${comcode}/${brcode}/`,  {},{
          params: {
            page: currentPage,
            status: status,
            start_date: startDate,
            end_date: endDate,
            type: "dwnld",
            // end_date: endDate,
          },
        })
        .then((response) => {
          // Set the fetched data
          //   console.log(date);
          const address = response.data.address;
          // Call downloadPDF after the data is fetched
          downloadPDF(
            date1,
            date2,
            response.data.data,
            address.cname,
            address.bname,
            address.address,
            address.email,
            address.phone
          );
        })
        .catch((err) => {
          console.log(err);
          alert("Error fetching data.");
        });
    }
  };

  const downloadPDF = (
    date1,
    date2,
    goldloans,
    cname,
    bname,
    address,
    email,
    phone
  ) => {
    if (!goldloans.length) {
      alert("No data available for download.");
      return;
    }

    const doc = new jsPDF();

    // Formatting Dates for Report Title (without timezone shift)
    const formatDate = (date) => {
      // Assuming the date is already in 'YYYY-MM-DD' format
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    };

    // Add title and period details

    const centerText = (doc, text, y) => {
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextWidth(text);
      const x = (pageWidth - textWidth) / 2;
      doc.text(text, x, y);
    };

    // Add title and period details
    doc.setFontSize(17);
    centerText(doc, cname, 15);
    doc.setFontSize(10);
    centerText(doc, address, 20);
    doc.setFontSize(10);
    centerText(doc, bname, 25);
    const emph = `${email} | ${phone}`;
    centerText(doc, emph, 30);
    doc.setFontSize(14);
    centerText(
      doc,
      `Gold Loans (${formatDate(date1)} To ${formatDate(date2)})`,
      35
    );
    // Prepare table data
    const rows = goldloans.map((goldloan) =>
      datakey.map((key) => goldloan[key] || "")
    );

    // Add table with auto-pagination
    doc.autoTable({
      head: [TABLE_HEAD],
      body: rows,
      startY: 40,
      theme: "striped",
      styles: { fontSize: 10 },
      columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 30 } }, // Customize column widths
      headStyles: {
        fillColor: [0, 0, 0], // Set the background color for the header (black in RGB)
        textColor: [255, 255, 255], // Set the text color for the header (white in RGB)
        fontSize: 12,
        fontStyle: "bold",
      },
      didDrawPage: (data) => {
        // Footer with page numbers
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber}`,
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        );
      },
    });

    // Save the PDF
    const fileName = `Gold Loans (${formatDate(date1)} To ${formatDate(
      date2
    )}).pdf`;
    doc.save(fileName);
  };

  return (
    <>
      <DashBoard />
      <div className="container mx-auto px-4 py-8 pt-20">
        <h2 className="text-2xl font-bold mb-4 text-center">
          Gold Loan Details
        </h2>

        <div className="right-0 text-bold">
          Total Number of Loans: {totalItems}
        </div>

        <div className="overflow-x-auto">
          <div className="overflow-y-auto">
            <div className="flex gap-2">
              <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
                <label className="mb-1 text-sm">Start Date:</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  max={getCurrentDate()}
                  className="border rounded px-2 border-black w-full"
                />
              </div>

              <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
                <label className="mb-1 text-sm">End Date:</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  max={getCurrentDate()}
                  min={startDate}
                  className="border rounded px-2 border-black w-full"
                />
              </div>
              <div className="flex flex-wrap w-full mt-5">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    fetchGoldLoans(currentPage);
                  }}
                  type="submit"
                  className="bg-gray-500 text-white px-4 py-1 h-8 rounded-md hover:bg-gray-600 w-full sm:w-auto"
                >
                  Submit
                </button>
                &nbsp;
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-1 h-8 rounded-md hover:bg-gray-600 w-full sm:w-auto"
                  onClick={fetchAndDownload}
                  disabled={goldLoans.length === 0}
                >
                  Download
                </button>
              </div>
            </div>
            {loading && <div className="spinner"></div>} {/* loading spinner */}
            <table className="min-w-full bg-white border-gray-200 shadow-sm rounded-lg overflow-hidden">
              <thead className="bg-gray-100">
                <tr>
                  <td className="px-4 py-1 text-left">Loan ID</td>
                  <td className="px-4 py-1 text-left">Full Name</td>
                  <td className="px-4 py-1 text-left">Amount</td>
                  <td className="px-4 py-1 text-left">Loan Date</td>
                  <td className="px-4 py-1 text-left">Due Date</td>
                  <td className="px-4 py-1 text-left">Gold Rate</td>
                  <td className="px-4 py-1 text-left">Customer ID</td>
                  <td className="px-4 py-1 text-left">FP Verified</td>
                  <td className="px-4 py-1 text-left">U-Code</td>
                </tr>
              </thead>
              <p>{error}</p>
              <tbody>
                {goldLoans.map((goldLoan, index) => (
                  <tr
                    key={index}
                    className="text-sm border-b border-gray-200 cursor-pointer hover:bg-gray-50"
                    onClick={() => openModal(goldLoan)}
                  >
                    <td className="px-4 ">{goldLoan.loanid}</td>
                    <td className="px-4 ">{goldLoan.full_name}</td>
                    <td className="px-4 ">{goldLoan.amount}</td>
                    <td className="px-4 ">{goldLoan.trdate}</td>
                    <td className="px-4 ">{goldLoan.duedate}</td>
                    <td className="px-4 ">{goldLoan.gold_rate}</td>
                    <td className="px-4 ">{goldLoan.customerid}</td>
                    <td className="px-4 ">
                      {goldLoan.fpverified ? "Yes" : "No"}
                    </td>
                    <td className="px-4 ">{goldLoan.ucode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {selectedGoldLoan && (
          <Modal
            isOpen={true}
            closeModal={closeModal}
            goldLoan={selectedGoldLoan}
          />
        )}
      </div>

      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        activePage={currentPage}
        setActivePage={handleSetActivePage}
        fetchdata={fetchGoldLoans}
      />
    </>
  );
};

export default GlReport;

