import React, { useContext, useEffect, useState } from "react";

import { FaBars, FaUserCircle } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";

import { NavTitle } from "../titles/titles";
import { AppContext } from "../context/appContext";
import { useNavigate } from "react-router";
import axios from "axios";

function NavBar({}) {
  let {
    title,
    sidebarToggle,
    setSidebarToggle,
    user,
    baseurl,
    setUser,
    setBrcode,
    setComcode,
    setUcode,
    authToken,
    setAuthToken,
    refreshToken,
    setRefreshToken,
    setGcode,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const logout = async () => {
    // // Optionally, clear the token from localStorage
    try {
      const data = {
        refresh: refreshToken
      }
      // Optional: Call the API to invalidate the session/token if needed
      console.log(refreshToken, 'refresh')
      await axios.post(`${baseurl}user/api/logout/`, {'refresh': refreshToken}, {
        headers: {
          Authorization: `Bearer ${authToken}`, // Send token if required
        },
      });

      // Remove the token from localStorage (for token-based auth)

      localStorage.removeItem("token") && localStorage.removeItem("token");
      localStorage.removeItem("atoken") && localStorage.removeItem("atoken");
      setAuthToken(null);
      setBrcode(null);
      setComcode(null);
      setUcode(null);
      setGcode(null);
      setRefreshToken(null)
      // localStorage.removeItem('user') && localStorage.removeItem('user');
      navigate("/");
      // Redirect to login page
      // or useNavigate('/login') in react-router v6
    } catch (error) {
      console.error("Logout error", error);
    }
  };

  return (
    <nav className="bg-gray-800 z-20 px-4 py-2 fixed w-full flex justify-between">
      <div className="flex items-center text-x1">
        <FaBars
          className="text-white me-4 cursor-pointer"
          onClick={() => {
            setSidebarToggle(!sidebarToggle);
          }}
        />

        <NavTitle name={title} />
      </div>

      <div className="relative">
        <span className="px-2 cursor-pointer font-semibold text-white">
          {user.name}
        </span>

        <button className="text-white group">
          <FaUserCircle className="w-6 h-6 mt-1" />
          <div
            className="z-20 hidden absolute bg-gray-400 rounded-lg
                     shadow w-60 group-focus:block top-full right-0"
          >
            <ul className="py-2 text-sm">
              {/* {/* <li>
                <a href="">Profile</a>
              </li> */}
              <li>
                <a href=""> </a>
              </li>
              <li
                onClick={logout}
                className="hover:shadow py-3 flex justify-center hover:bg-gray-300"
              >
                <span className="font-bold" style={{ color: "red" }}>
                  Log Out
                </span>
                <FaSignOutAlt
                  style={{ color: "red" }}
                  className="w-5 ms-2 h-5"
                />
              </li>
            </ul>
          </div>
        </button>
      </div>
    </nav>
  );
}

export default NavBar;
