import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../context/appContext";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

function SideBar({}) {
  let { sidebarToggle, sideNavRef, subMenuOpen, setSubMenuOpen } =
    useContext(AppContext);
  let { pathname } = useLocation();
  let menuBgColor = "bg-gray-600";
  let page_url = pathname.split("/")?.[1];
  const navigate = useNavigate();
  function activeLink(type = null) {
    type = type && type.split("/")[1];
    // console.log(type && type.split("/")[1],'lop')
    if (type === page_url) {
      return true;
    } else {
      return false;
    }
  }

  const menu = [
    { title: "Home Page", url: "/homepage" },
    {
      title: "Customers",
      subMenu: [
        {
          title: "Create Customer",
          url: "/customer",
        },
        {
          title: "Customer List",
          url: "/customerlist",
        },
        {
          title: "Aadhar List",
          url: "/aadhaarlist",
        },
      ],
    },
    {
      title: "Loan",
      subMenu: [
        {
          title: "Goldloan",
          url: "/goldloan",
        },
        {
          title: "Goldloan Close",
          url: "/loanclose",
        },
        {
          title: "Goldloan List",
          url: "/getgllist",
        },
        {
          title: "Outstanding Goldloan List",
          url: "/Outstanding-goldloan-list",
        },
        {
          title: "Closed Goldloan List",
          url: "/closed-goldloan-list",
        },

        {
          title: "Old Goldloan Numbers",
          url: "/Loancheck",
        },
        {
          title: "Gold Rate",
          url: "/goldrate",
        },
        {
          title: "Print GoldLoan",
          url: "/searchloan",
        },
        {
          title: "GoldLoan Transactions",
          url: "/goldloantrans",
        },
      ],
    },
    {
      title: "Payment And Reciept",
      subMenu: [
        {
          title: "Payment",
          url: "/payment",
        },
        {
          title: "Reciept",
          url: "/receipt",
        },
      ],
    },
    {
      title: "DayBook",
      subMenu: [
        {
          title: "Day Book",
          url: "/daybook",
        },
        {
          title: "DayBook Aprove",
          url: "/daybook-aprove",
        },
        {
          title: "DayBook Edit Date",
          url: "/editdaybookdate",
        },
        {
          title: "Cash Book",
          url: "/cashbook",
        },
        {
          title: "Bank Transaction",
          url: "/Bank-transactions",
        },
        {
          title: "Stamp Duty Report",
          url: "/getstampduty",
        },
        {
          title: "DP Statement",
          url: "/dpstatement",
        },
        {
          title: "Cibil",
          url: "/cibil",
        },
        {
          title: "Test.....?",
          url: "/test",
        },
      ],
    },
    {
      title: "Users",
      subMenu: [
        {
          title: "User",
          url: "/register",
        },
        {
          title: "User List",
          url: "/userlist",
        },
        {
          title: "Assign Role User",
          url: "/assign-role-user",
        },
        {
          title: "Assign Role",
          url: "/assign-page-role",
        },
        {
          title: "Add Role",
          url: "/add-role",
        },
        {
          title: "Page List",
          url: "/pagelist",
        },
        {
          title: "Add Pages",
          url: "/add-page",
        },
      ],
    },
    {
      title: "Company",
    },
    {
      title: "Branch",
      subMenu: [
        {
          title: "Add Branch",
          url: "/branch",
        },
        {
          title: "Branch List",
          url: "/branchlist",
        },
      ],
    },
    {
      title: "Settings",
      subMenu: [
        {
          title: "Add Pledge Items",
          url: "/items",
        },
        {
          title: "Goldrate",
          url: "/goldrate",
        },
        {
          title: "Goldrate List",
          url: "/getgoldrate",
        },
        {
          title: "Bank",
          url: "/addbank",
        },
        {
          title: "Bank List",
          url: "/getbank",
        },
        {
          title: "Bank Entry",
          url: "/bankentry",
        },
        {
          title: "Loan Scheme",
          url: "/loan-scheme",
        },
        { title: "Nature of Loan", url: "/nature-of-loan" },
        { title: "Add Pledudge Item", url: "/items" },
        {
          title: "Country, State & City",
          url: "/register-country",
        },
        { title: "Type of Transaction(Mode)", url: "/Add-transation" },
        { title: "Type of Transaction List", url: "/translist" },
        { title: "Voucher Head", url: "/voucherhead" },
        { title: "Voucher Head List", url: "/voucherheadlist" },
        { title: "Account head", url: "/add-account-head" },
        { title: "Account head List", url: "/accountingheadlist" },
        { title: "Group head", url: "/add-group-head" },
        { title: "Group head List", url: "/groupheadlist" },

        { title: "otp", url: "/demo" },
      ],
    },
    {
      title: "Change Password",
      url: "/reset-password",
    },
  ];

  return (
    <div
      ref={sideNavRef}
      className={`bg-gray-800 z-20 fixed h-full duration-500 ${
        sidebarToggle ? "w-0" : "w-64"
      } overflow-scroll`}
      style={{ marginTop: "50px" }}
    >
      {/* <div className='my-2 mb-4'>
                <h1 className='text-2x text-white font-bold'>Dashboard</h1>
            </div> */}

      <ul
        className={`'mt-3 text-white text-sm duration-500 ${
          sidebarToggle && "scale-0"
        }`}
      >
        {menu &&
          menu.map(({ title, subMenu, url }, index) => (
            <>
              <li
                key={index}
                className={`px-5 cursor-pointer hover:shadow hover:bg-gray-600 py-2 flex justify-between ${
                  url && activeLink(url) && menuBgColor
                }`}
                onClick={() => {
                  setSubMenuOpen((prev) => ({ [title]: !prev[title] }));
                  navigate(url);
                }}
              >
                <span>{title}</span>
                {subMenu && (
                  <ChevronDownIcon
                    className={`w-5 h-5 ${
                      subMenuOpen[title] && "rotate-180 duration-500"
                    }`}
                  />
                )}
              </li>

              {subMenu && subMenuOpen[title] && (
                <ul className={`bg-gray-700`}>
                  {subMenu.map((subMenu, index) => (
                    <>
                      <li
                        key={`${index}${subMenu.title}`}
                        className={`ps-14 cursor-pointer hover:shadow hover:bg-gray-600 py-2 ${
                          activeLink(subMenu.url) && menuBgColor
                        }`}
                        onClick={() => navigate(subMenu.url)}
                      >
                        {subMenu.title}
                      </li>
                    </>
                  ))}
                </ul>
              )}
            </>
          ))}
      </ul>
    </div>
  );
}

export default SideBar;
