import React, { useState, useContext } from "react";
import axios from "axios";
import Modal from "./goldmodal"; // Assuming the Modal component file is in the same directory
import { AppContext } from "../../context/appContext";
import Pagination from "../../components/Pagination";
import DashBoard from "../../components/DashBoard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AlertMessage from "../../components/alert/Alert";

const PendingGoldLoanTable = () => {
  const today = new Date().toISOString().split("T")[0];
  const [goldLoans, setGoldLoans] = useState([]);
  const [totalWeight, setTotalweight] = useState("");
  const [totalNetWeight, setTotalnetweight] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [selectedGoldLoan, setSelectedGoldLoan] = useState(null);
  const { baseurl, comcode, brcode } = useContext(AppContext);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");
  const [open1, setOpen1] = useState(false); //For Alert
  const [successMessage1, setSuccessMessage1] = useState(); //For Alert

  const itemsPerPage = 16;
  const [status, setStatus] = useState("N");
  const [endDate, setEndDate] = useState(today);
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const TABLE_HEAD = [
    "Loan ID",
    "Name",
    "Amount",
    "Loan Date",
    "Due Date",
    "No",
    "Weight",
    "Net Weight",
  ];
  const datakey = [
    "loanid",
    "full_name",
    "amount",
    "trdate",
    "duedate",
    "total_count",
    "total_weight",
    "total_ntweight",
  ];

  //   useEffect(() => {
  //     fetchGoldLoans();
  //   }, [currentPage]);

  const fetchGoldLoans = async (currentPage) => {
    setDate(endDate);
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseurl}goldloan/get-goldloan/${comcode}/${brcode}/`,
        {
          // const response = await axios.post(
          //   `http://localhost:8000/outstanding/${comcode}/${brcode}/`,
          //   {},

          params: {
            page: currentPage,
            status: status,
            end_date: endDate,
            type: "view",
            // end_date: endDate,
          },
        }
      );
      if (!response.data.results.data.length) {
        setGoldLoans([]);
        setTotalItems("0");
        setOpen1(true); //For Alert
        setSuccessMessage1(`Data Not Found.`); //For Alert

        return;
      }
      setTotalweight(response.data.results.sum_total_weight);
      setTotalnetweight(response.data.results.sum_total_nt_weight);
      setTotalAmount(response.data.results.totalamount);
      setGoldLoans(response.data.results.data); // Assuming your API response structure

      setTotalItems(response.data.count);
    } catch (error) {
      console.log("Error fetching data: ", error);
      setGoldLoans([]);
      setTotalItems("0");
      setOpen1(true); //For Alert
      setSuccessMessage1(`Data Not Found.`); //For Alert
    }
    setLoading(false);
  };

  const getCurrentDate = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    return currentDate;
  };

  const openModal = (goldLoan) => {
    setSelectedGoldLoan(goldLoan);
  };

  const closeModal = () => {
    setSelectedGoldLoan(null);
  };
  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const fetchAndDownload = async () => {
    setLoading(true);

    if (date) {
      // Fetch data from API
      const response = await axios
        .get(`${baseurl}goldloan/get-goldloan/${comcode}/${brcode}`, {
          params: {
            page: currentPage,
            status: status,
            end_date: endDate,
            type: "dwnld",
            // end_date: endDate,
          },
        })
        .then((response) => {
          // Set the fetched data
          console.log(date);
          const address = response.data.address;
          // Call downloadPDF after the data is fetched
          downloadPDF(
            date,
            response.data.data,
            address.cname,
            address.bname,
            address.address,
            address.email,
            address.phone,
            totalWeight,
            totalNetWeight,
            totalItems,
            totalAmount
          );
          setLoading(false);
        })
        .catch((err) => {
          setOpen1(true); //For Alert
          setSuccessMessage1(`Data Not Found.`); //For Alert
          setLoading(false);
        });
    }
  };

  const downloadPDF = (
    date,
    goldloans,
    cname,
    bname,
    address,
    email,
    phone,
    totalWeight,
    totalNetWeight,
    totalItems
  ) => {
    if (!goldloans || goldloans.length === 0) {
      alert("No data available for download.");
      return;
    }

    if (!datakey || datakey.length === 0) {
      alert("No data keys defined.");
      return;
    }

    const doc = new jsPDF();

    // Formatting Dates for Report Title (without timezone shift)
    const formatDate = (date) => {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    };

    // Add title and period details
    const centerText = (doc, text, y) => {
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextWidth(text);
      const x = (pageWidth - textWidth) / 2;
      doc.text(text, x, y);
    };

    // Add title and period details
    doc.setFontSize(17);
    centerText(doc, cname, 15);
    doc.setFontSize(10);
    centerText(doc, address, 20);
    doc.setFontSize(10);
    centerText(doc, bname, 25);
    const emph = `${email} | ${phone}`;
    centerText(doc, emph, 30);
    doc.setFontSize(14);
    centerText(doc, `Outstanding Gold Loans (${formatDate(date)})`, 35);

    // Prepare table data
    const rows = goldloans.map((goldloan) =>
      datakey.map((key) => goldloan[key] || "")
    );

    // Add table with auto-pagination
    doc.autoTable({
      head: [TABLE_HEAD],
      body: rows,
      startY: 45,
      theme: "striped",
      styles: { fontSize: 10 },
      columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 30 } }, // Customize column widths
      headStyles: {
        fillColor: [0, 0, 0], // Set the background color for the header (black in RGB)
        textColor: [255, 255, 255], // Set the text color for the header (white in RGB)
        fontSize: 12,
        fontStyle: "bold",
      },
      didDrawPage: (data) => {
        // Footer with page numbers
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber}`,
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        );
      },
    });
// Now calculate the yOffset for the totals below the table
const yOffset = doc.autoTableEndPosY() + 10; // Position below the table

// Draw a line (ruler) under the table
const pageWidth = doc.internal.pageSize.width;
const lineY = yOffset - 5; // Position just above the total text area
doc.line(10, lineY, pageWidth - 10, lineY); // Draw the line from left to right of the page

// Set font size and bold for totals text
doc.setFontSize(12);
doc.setFont("helvetica", "bold");

// Left margin for labels and right margin for values
const leftMargin = 20; // Left margin for the labels
const valueMargin = 120; // Margin for the values to ensure alignment, adjust as needed

// Prepare the total text with labels and values
const totalText1 = `Total Gross Weight:`;
const totalText2 = `Total Net Weight:`;
const totalText3 = `Total Count:`;
const totalText4 = `Total Amount:`;

const totalValue1 = `${totalWeight || 0}`;
const totalValue2 = `${totalNetWeight || 0}`;
const totalValue3 = `${totalItems || 0}`;
const totalValue4 = `${totalAmount || 0}`;

// Check if there's enough space for totals on the current page
const pageHeight = doc.internal.pageSize.height;
const remainingSpace = pageHeight - yOffset - 10; // Space remaining on the current page

// If not enough space, add a new page and set totals at the top
if (remainingSpace < 30) {
  doc.addPage();
  // After adding the new page, we position the totals at the top of this new page
  const newYOffset = 20; // Adjust y-offset for the new page top
  doc.text(totalText1, leftMargin, newYOffset);
  doc.text(totalValue1, valueMargin, newYOffset);
  
  doc.text(totalText2, leftMargin, newYOffset + 7); // Reduced the space from 10 to 7 for tighter spacing
  doc.text(totalValue2, valueMargin, newYOffset + 7); // Align value with label
  
  doc.text(totalText3, leftMargin, newYOffset + 14); // Reduced the space from 10 to 7 for tighter spacing
  doc.text(totalValue3, valueMargin, newYOffset + 14); // Align value with label
  
  doc.text(totalText4, leftMargin, newYOffset + 21); // Reduced the space from 10 to 7 for tighter spacing
  doc.text(totalValue4, valueMargin, newYOffset + 21); // Align value with label
} else {
  // Write totals below the table if space is available
  doc.text(totalText1, leftMargin, yOffset);
  doc.text(totalValue1, valueMargin, yOffset);

  doc.text(totalText2, leftMargin, yOffset + 7); // Reduced the space from 10 to 7 for tighter spacing
  doc.text(totalValue2, valueMargin, yOffset + 7); // Align value with label
  
  doc.text(totalText3, leftMargin, yOffset + 14); // Reduced the space from 10 to 7 for tighter spacing
  doc.text(totalValue3, valueMargin, yOffset + 14); // Align value with label
  
  doc.text(totalText4, leftMargin, yOffset + 21); // Reduced the space from 10 to 7 for tighter spacing
  doc.text(totalValue4, valueMargin, yOffset + 21); // Align value with label
}

// Draw a line (ruler) under the totals
const totalLineY = yOffset + 22; // Adjust position below the last total text
doc.line(10, totalLineY, pageWidth - 10, totalLineY); // Draw the line from left to right of the page

    const fileName = `Outstanding Gold Loans (${formatDate(date)}).pdf`;
    doc.save(fileName);
  };

  return (
    <>
      <DashBoard />

      <div className="container mx-auto px-4 py-8 pt-20">
        <h2 className="text-2xl font-bold mb-4 text-center">
          Outstanding Gold Loan Details
        </h2>

        {successMessage1 && open1 && (
          <AlertMessage
            color="red"
            open={open1}
            setOpen={setOpen1}
            message={successMessage1}
          />
        )}
        {/* For Alert*/}

        <div className="right-0 text-bold">
          Total Number of Loans: {totalItems}
        </div>
        <div className="right-0 text-bold">Total Amount: {totalAmount}</div>

        <div className="overflow-x-auto">
          <div className="overflow-y-auto">
            <div className="flex gap-2">
              <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
                <label className="mb-1 text-sm">Date:</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  max={getCurrentDate()}
                  className="border rounded px-2 border-black w-full"
                />
              </div>

              {/* <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
                <label className="mb-1 text-sm">End Date:</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  max={getCurrentDate()}
                  min={startDate}
                  className="border rounded px-2 border-black w-full"
                />
              </div> */}
              <div className="flex flex-wrap w-full mt-5">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    fetchGoldLoans(currentPage);
                  }}
                  type="submit"
                  className="bg-gray-500 text-white px-4 py-1 h-8 rounded-md hover:bg-gray-600 w-full sm:w-auto"
                >
                  Submit
                </button>
                &nbsp;
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-1 h-8 rounded-md hover:bg-gray-600 w-full sm:w-auto"
                  onClick={fetchAndDownload}
                  disabled={goldLoans.length === 0}
                >
                  Download
                </button>
              </div>
            </div>
            {loading && <div className="spinner"></div>} {/* loading spinner */}
            <table className="min-w-full bg-white border-gray-200 shadow-sm rounded-lg overflow-hidden">
              <thead className="bg-gray-100">
                <tr>
                  <td className="px-4 py-1 text-left">Loan ID</td>
                  <td className="px-4 py-1 text-left">Full Name</td>
                  <td className="px-4 py-1 text-left">Amount</td>
                  <td className="px-4 py-1 text-left">Loan Date</td>
                  <td className="px-4 py-1 text-left">Due Date</td>
                  <td className="px-4 py-1 text-left">Gold Rate</td>
                  <td className="px-4 py-1 text-left">No</td>
                  <td className="px-4 py-1 text-left">Weight</td>
                  <td className="px-4 py-1 text-left">Net Weight</td>
                  <td className="px-4 py-1 text-left">U-Code</td>
                </tr>
              </thead>
              <p>{error}</p>
              <tbody>
                {goldLoans.map((goldLoan, index) => (
                  <tr
                    key={index}
                    className="text-sm border-b border-gray-200 cursor-pointer hover:bg-gray-50"
                    onClick={() => openModal(goldLoan)}
                  >
                    <td className="px-4 ">{goldLoan.loanid}</td>
                    <td className="px-4 ">{goldLoan.full_name}</td>
                    <td className="px-4 ">{goldLoan.amount}</td>
                    <td className="px-4 ">{goldLoan.trdate}</td>
                    <td className="px-4 ">{goldLoan.duedate}</td>
                    <td className="px-4 ">{goldLoan.gold_rate}</td>
                    <td className="px-4 ">{goldLoan.total_count}</td>
                    <td className="px-4 ">{goldLoan.total_weight}</td>
                    <td className="px-4 ">{goldLoan.total_ntweight}</td>
                    <td className="px-4 ">{goldLoan.ucode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {selectedGoldLoan && (
          <Modal
            isOpen={true}
            closeModal={closeModal}
            goldLoan={selectedGoldLoan}
          />
        )}
      </div>

      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        activePage={currentPage}
        setActivePage={handleSetActivePage}
        fetchdata={fetchGoldLoans}
      />
    </>
  );
};

export default PendingGoldLoanTable;
