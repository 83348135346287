import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Customer from "./pages/customer/customer";
import GoldLoanApplication from "./pages/goldloanapplication/goldloanapplication";
import GoldLoan from "./pages/goldloan/goldloan";
import LoanCloseProvider from "./pages/loanclose/LoanCloseContext";
import RegisterUser from "./pages/registeruser/registeruser";
import PasswordReset from "./pages/passwordrest/passwordreset";
import Login from "./pages/login/login";
import Payment from "./pages/payment/payment";
import Receipt from "./pages/receipt/receipt";
import Company from "./pages/companybranch/company";
import Branch from "./pages/companybranch/branch";
import GoldRate from "./pages/goldrateupdate/goldrate";
import DayBook from "./pages/daybook/daybook";
import LoanCloseList from "./pages/loanclose/loancloselist";
import LoanList from "./pages/goldloan/loanlist";
// import Outstanding from "./pages/outstanding/Outstanding";
import BankEntry from "./pages/bankentry/bankentry";
import CustomerList from "./pages/customer/customerlist";
import UserList from "./pages/registeruser/listuser";
import BranchList from "./pages/companybranch/branchlist";
import CompanyDetails from "./pages/companybranch/companydetails";
import DaybookTable from "./pages/daybook/daybookAprove";
import VoucherHead from "./pages/voucher/voucherhead";
import NatureOfLoanForm from "./pages/natureofloan/loannature";
import Scheme from "./pages/schemes/scheme";
import TypeOfTransations from "./pages/typeoftran/typeoftransation";
import RegisterCountry from "./pages/country/countrycode";
import Item from "./pages/item/item";
import Bank from "./pages/bankentry/addbank";
import AccountingHead from "./pages/accountingheads/accountinghead";
import GroupCode from "./pages/accountingheads/grouphead";
import Tests from "./pages/test/test";
import Mywork from "./pages/test/mywork";
import SearchLoan from "./pages/searchloan/loanapp";
import HomePage from "./pages/homepage/Daigram";
import PrivateRoute from "./privateroute/PrivateRoute";
import FingerPrint from "./pages/fingerprint/fingerprint";
import UserRole from "./pages/role/role";
import AddRole from "./pages/role/role";
import AssignPage from "./pages/role/assignpage";
import AddPage from "./pages/role/addpage";
import PageList from "./pages/role/pagelist";
import LoanClose from "./pages/loanclose/loanclose";
import ClosedGoldLoanTable from "./pages/goldloan/closedgoldlist";
import PendingGoldLoanTable from "./pages/goldloan/outstandinggoldlist";
import AssignRoleToUser from "./pages/role/assignrole";
import DaybookDetails from "./pages/daybook/daybooklist";
import CashbookList from "./pages/daybook/cashbook";
import BankbookList from "./pages/daybook/bankbook";
import Demo from "./pages/demo/demo";
import AadhaarProvider from "./context/aadhaarContext";
import AadhaarList from "./pages/customer/aadhaarlist";
import AccountingHeadList from "./pages/accountingheads/accountingheadlist";
import GroupHeadList from "./pages/accountingheads/groupheadlist";
import TransactionTypeList from "./pages/typeoftran/transactiontypelist";
import VoucherHeadList from "./pages/voucher/voucherheadlist";
import GoldrateList from "./pages/goldrateupdate/goldratelist";
import BankList from "./pages/bankentry/addbanklist";
import DaybookEditList from "./pages/editglanddaybookdate/daybookedit";
import GoldLoanEditList from "./pages/editglanddaybookdate/goldloanedit";
import VoucherPrint from "./pages/loanclose/loanclosevoucherprint";
import OpeningVoucherPrint from "./pages/loanclose/loanopenvoucherprint";
import PanTicketKannada from "./pages/searchloan/panticketkannada";
import ApprovalGoldLoanList from "./pages/goldloan/ApprovalGoldLoanList";
import Transactions from "./pages/daybook/daybookclosing";
import LoanCheck from "./pages/goldloan/oldloanno";
import GlReport from "./pages/goldloan/GlReport";
import BankTransactions from "./pages/daybook/BankTransactions";
import GltrDetails from "./pages/goldloan/GltrDetails";
import PaymentVoucher from "./pages/payment/PaymentVoucher";
import ReceiptVoucher from "./pages/receipt/ReceiptVoucher";
import StampdutyReport from "./pages/daybook/StampdutyReport";
import DPStatement from "./pages/daybook/DPStatement";
import Kannada from "./pages/searchloan/Kannada";
import SdVoucherPrint from "./pages/loanclose/SdVoucherPrint";
import DocVoucherPrint from "./pages/loanclose/DocVoucherPrint";
import PasswordResetForm from "./pages/passwordrest/passwordresetform";
import Cibilgenerate from "./pages/daybook/Cibilgenerate";
import SuccessModal from "./components/modal/SuccessModal2";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} exact />
          <Route
            path="customer"
            element={
              // <PrivateRoute page={3}>
                <AadhaarProvider>
                  <Customer />
                </AadhaarProvider>
              // </PrivateRoute>
            }
          />
          <Route path="goldloan" element={<GoldLoan />} />
          
          <Route path="register" element={<RegisterUser />} />
          <Route
            path="loanclose"
            element={
              <LoanCloseProvider>
                <LoanClose />
              </LoanCloseProvider>
            }
          />
          <Route path="reset-password" element={<PasswordReset />} />

          <Route path="payment" element={<Payment />} />
          <Route path="receipt" element={<Receipt />} />
          <Route path="company" element={<Company />} />
          <Route path="branch" element={<Branch />} />
          <Route path="branchlist" element={<BranchList />} />
          <Route path="test" element={<DayBook />} />
          <Route path="goldrate" element={<GoldRate />} />
          <Route path="goldloanlist" element={<LoanCloseList />} />
          <Route path="pendinglist" element={<LoanList />} />
          {/* <Route path="outstanding" element={<Outstanding />} /> */}
          <Route
            path="customerlist"
            element={
              // <PrivateRoute page={4}>
                <CustomerList />
              // </PrivateRoute>
            }
          />
          <Route path="company-details" element={<CompanyDetails />} />
          <Route
            path="userlist"
            element={
              // <PrivateRoute page={5}>
                <UserList />
              // </PrivateRoute>
            }
          />
          <Route path="bankentry" element={<BankEntry />} />
          <Route path="daybook-aprove" element={<DaybookTable />} />
          <Route path="voucherhead" element={<VoucherHead />} />
          <Route path="nature-of-loan" element={<NatureOfLoanForm />} />
          <Route path="loan-scheme" element={<Scheme />} />
          <Route path="add-transation" element={<TypeOfTransations />} />
          <Route path="register-Country" element={<RegisterCountry />} />
          <Route path="items" element={<Item />} />
          <Route path="addbank" element={<Bank />} />
          <Route path="add-account-head" element={<AccountingHead />} />
          <Route path="add-group-head" element={<GroupCode />} />
          <Route path="tests" element={<Tests />} />
          <Route path="mywork" element={<Mywork />} />
          <Route path="searchloan" element={<SearchLoan />} />
          <Route
            path="homepage"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route path="fingerprint" element={<FingerPrint />} />
          <Route path="add-role" element={<AddRole />} />
          <Route path="assign-page-role" element={<AssignPage />} />
          <Route
            path="closed-goldloan-list"
            element={<ClosedGoldLoanTable />}
          />
          <Route
            path="Outstanding-goldloan-list"
            element={<PendingGoldLoanTable />}
          />
          <Route path="add-page" element={<AddPage />} />
          <Route path="Pagelist" element={<PageList />} />
          <Route path="loanclose" element={<LoanClose />} />
          <Route path="assign-role-user" element={<AssignRoleToUser />} />
          <Route path="daybook" element={<DaybookDetails />} />
          <Route path="Cashbook" element={<CashbookList />} />
          <Route path="Bank-Entry-List" element={<BankbookList />} />
          <Route path="aadhaarlist" element={<AadhaarList />} />
          <Route path="demo" element={<Demo />} />
          <Route path="accountingheadlist" element={<AccountingHeadList />} />
          <Route path="groupheadlist" element={<GroupHeadList />} />
          <Route path="translist" element={<TransactionTypeList />} />
          <Route path="voucherheadlist" element={<VoucherHeadList />} />
          <Route path="getgoldrate" element={<GoldrateList />} />
          <Route path="getbank" element={<BankList />} />
          <Route path="editdaybookdate" element={<DaybookEditList />} />
          <Route path="editgoldloandate" element={<GoldLoanEditList />} />
          <Route path="loan-close-voucher" element={<VoucherPrint />} />
          <Route path="payment-voucher" element={<PaymentVoucher />} />
          <Route path="receipt-voucher" element={<ReceiptVoucher />} />
          <Route
            path="loan-opening-voucher"
            element={<OpeningVoucherPrint />}
          />
          <Route
            path="loan-sd-voucher"
            element={<SdVoucherPrint />}
          />
          <Route
            path="loan-doc-voucher"
            element={<DocVoucherPrint />}
          />
          <Route path="loan-print-kannada" element={<Kannada />} />
          {/* <Route path="loan-print-kannada" element={<PanTicketKannada />} /> */}
          <Route path="approve-goldloan" element={<ApprovalGoldLoanList />} />
          <Route path="Transactions" element={<Transactions />} />
          <Route path="Loancheck" element={<LoanCheck />} />
          <Route path="getgllist" element={<GlReport />} />
          <Route path="Bank-transactions" element={<BankTransactions />} />
          <Route path="getstampduty" element={<StampdutyReport />} />
          <Route path="goldloantrans" element={<GltrDetails />} />
          <Route path="dpstatement" element={<DPStatement/>} />
          <Route path="pwdreset" element={<PasswordResetForm/>} />
          <Route path="cibil" element={<Cibilgenerate/>} />
          <Route path="modal2" element={<SuccessModal/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
