import React, { createContext, useEffect, useState ,useContext} from 'react';
import {useForm } from "react-hook-form"
import axios from "axios";
import { AppContext } from "../../context/appContext";

export const  LoanCloseContext = createContext()
function LoanCloseProvider({children}) {
    const [sajdata, setData] = useState(null);
    const [val1, setval1] = useState(0);
    const [total, setTotal] = useState(0);
    const [total1, setTotal1] = useState(0);
    const [finaldc11, setFinaldc11] = useState(0);
    const [finalnotice11, setFinalnotice11] = useState(0);
    const [finalgst11, setFinalgst11] = useState(0);
    const [finalotc11, setFinalotc11] = useState(0);
    const [finalinterest11, setFinalinterest11] = useState(0);
    const [finalamt11, setFinalamt11] = useState(0);
    const [texname, setName] = useState(0);
    const [open1, setOpen1] = useState(false)  
    const [view, setView] = useState(false)  
    const [successMessage1, setSuccessMessage1] = useState()
    const [mode, setMode] = useState()
    const [runningTotal, setRunningTotal] = useState(0);
    let [amnt, setAmnt] = useState({});
    const {  baseurl, } =
    useContext(AppContext);
    
    





    const handleAmount = (e) => {
        let amount = parseFloat(e);
        let finalAmount = 0;
    
        if (loan.dc <= amount && amount) {
          amount -= loan.dc;
          finalAmount += loan.dc;
          setValue("finaldc1", loan.dc);
          setFinaldc11(loan.dc);
        } else if (loan.dc > amount && amount) {
          setValue("finaldc1", amount);
          setFinaldc11(amount);
    
          finalAmount += amount;
          amount -= amount;
        } else {
          setValue("finaldc1", 0);
          setFinaldc11(0);
        }
        if (loan.notice <= amount && amount) {
          amount -= loan.notice;
          finalAmount += loan.notice;
          setValue("finalnotice1", loan.notice);
          setFinalnotice11(loan.notice);
        } else if (loan.notice > amount && amount) {
          setValue("finalnotice1", amount);
          setFinalnotice11(amount);
          finalAmount += amount;
          amount -= amount;
        } else {
          setValue("finalnotice1", 0);
          setFinalnotice11(0);
        }
    
        if (loan.gst <= amount && amount) {
          setValue("finalgst", loan.gst);
          setFinalgst11(loan.gst);
    
          finalAmount += loan.gst;
          amount -= loan.gst;
        } else if (loan.gst > amount) {
          setValue("finalgst", amount);
          setFinalgst11(amount);
    
          finalAmount += amount;
          amount -= amount;
        } else {
          setValue("finalgst", 0);
          setFinalgst11(0);
        }
    
        if (loan.otc <= amount && amount) {
          setValue("finalotc", loan.otc);
    
          setFinalotc11(loan.otc);
          finalAmount += loan.otc;
          amount -= loan.otc;
        } else if (loan.otc > amount && amount) {
          setValue("finalotc", amount);
    
          setFinalotc11(amount);
          finalAmount += amount;
          amount -= amount;
        } else {
          setValue("finalotc", 0);
          setFinalotc11(0);
        }
    
        if (loan.interest <= amount && amount) {
          setValue("finalInterest", loan.interest);
    
          setFinalinterest11(loan.interest);
          finalAmount += loan.interest;
          amount -= loan.interest;
        } else if (loan.interest > amount && amount) {
          setValue("finalInterest", amount);
    
          setFinalinterest11(amount);
    
          finalAmount += amount;
          amount -= amount;
        } else {
          setValue("finalInterest", 0);
          setFinalinterest11(0);
        }
        if ((loan.amount === amount || loan.amount > amount) && amount) {
          setValue("finalamount", amount);
    
          setFinalamt11(amount);
          finalAmount += amount;
          amount -= loan.amount;
        } else if (loan.amount < amount && amount) {
          amount -= loan.amount;
          finalAmount += loan.amount;
          setValue("finalamount", loan.amount);
    
          setFinalamt11(loan.amount);
        } else {
          setValue("finalamount", 0);
          setFinalamt11(0);
        }
        setValue("finalAmnt", finalAmount);
        setTotal1(finalAmount);
        return total1;
    
        // setTotal1(finalAmount)
        //console.log('finalAmnt', amount);
        //console.log('finalAmnt', finalAmount);
      };











    
    // console.log('oooooooooo',finaldc11);
    
    const {
        register,
        setValue,
       
        formState: { errors },
      } = useForm();
      const loan = {
          'amount': sajdata && sajdata.total_values.balamount,
          'gst': sajdata && sajdata.total_values.Servicecharge,
          'interest': sajdata && sajdata.total_values.intrestamt,
          'notice': sajdata && sajdata.total_values.notice,
          'otc': sajdata && sajdata.total_values.othercharge,
          'dc': sajdata && sajdata.total_values.documentation,         
          
        }

        useEffect(()=>{
            axios
            .get(`${baseurl}predbvalue/transaction-type/`)
            .then(function (response) {
              // handle success
              // console.log('op', response.data.data);
              setMode(response.data.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
      
        },[])
    
    // console.log('context', total1);
    
   
    return (
        <LoanCloseContext.Provider value={{
            loan,sajdata,setData,total,setTotal,total1, setTotal1,finaldc11, setFinaldc11,
            finalnotice11, setFinalnotice11,finalgst11, setFinalgst11,amnt, setAmnt,
            finalotc11, setFinalotc11,finalinterest11, setFinalinterest11,finalamt11, setFinalamt11,view, setView,
            texname, setName, open1, setOpen1,successMessage1, setSuccessMessage1,mode,setMode,val1,setval1,runningTotal, setRunningTotal,handleAmount,
    
        }}>
            {children}
        </LoanCloseContext.Provider>
    );
}
export default LoanCloseProvider;