import React, { useContext, useEffect } from "react";
import { Stepper, Step, Button, Typography } from "@material-tailwind/react";
import StepDisplay from "../stepDisplay/StepDisplay";
import { useForm } from "react-hook-form";
import { LoanContext } from "../../context/loanContext";

export function DefaultStepper() {
  const {
    // register,
    // setValue,
    // handleSubmit,
    formState: { errors },
  } = useForm();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);
  let [currentStep, setCurrentStep] = React.useState(1);
  let [status, setStatus] = React.useState(false);

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);
  // const nextButton = (formData)=>{
  //   formData.step1.
  // }
  let { formData, setFormData, stepLoan, customerDetail } =
    useContext(LoanContext); // form data

  const stepClass = "absolute -bottom-[1.5rem] w-max text-center";
  useEffect(() => {
    setStatus(stepLoan(formData, currentStep));
    if (customerDetail.pan) {
      console.log(customerDetail.pan);
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (!panRegex.test(customerDetail.pan)) {
        console.log("Invalid PAN number");
        setStatus(true);
      } else {
        console.log("Valid PAN number");
        setStatus(false);

      }
    }
  }, [
    currentStep,
    formData.goldloan,
    formData.amount,
    formData.details,
    customerDetail.pan,
  ]);

  return (
    <div className="w-full px-14">
      <Stepper
        activeStep={activeStep}
        isLastStep={(value) => setIsLastStep(value)}
        isFirstStep={(value) => setIsFirstStep(value)}
        activeLineClassName="bg-green-700"
      >
        <Step
          activeClassName="bg-blue-500"
          completedClassName="bg-green-700"
          onClick={() => {
            setActiveStep(0);
            setCurrentStep(1);
          }}
        >
          1
          <div className={stepClass}>
            <Typography
              variant="h6"
              className="font-normal hover:font-bold text-xs"
              color={activeStep === 0 ? "blue-gray" : "gray"}
            >
              Application
            </Typography>
          </div>
        </Step>

        <Step
          activeClassName="bg-blue-500"
          completedClassName="bg-green-700"
          onClick={() => {
            !status && setActiveStep(1);
            !status && setCurrentStep(2);
          }}
        >
          2
          <div className={stepClass}>
            <Typography
              variant="h6"
              className="font-normal hover:font-bold text-xs"
              color={activeStep === 0 ? "blue-gray" : "gray"}
            >
              Gold Loan
            </Typography>
          </div>
        </Step>

        <Step
          activeClassName="bg-blue-500"
          completedClassName="bg-green-700"
          onClick={() => {
            !status && setActiveStep(2);
            !status && setCurrentStep(3);
          }}
        >
          3
          <div className={stepClass}>
            <Typography
              variant="h6"
              className="font-normal hover:font-bold text-xs"
              color={activeStep === 0 ? "blue-gray" : "gray"}
            >
              Pledge
            </Typography>
          </div>
        </Step>

        <Step
          activeClassName="bg-blue-500"
          completedClassName="bg-green-700"
          onClick={() => {
            setActiveStep(3);
            setCurrentStep(4);
          }}
        >
          4
          <div className={stepClass}>
            <Typography
              variant="h6"
              className="font-normal hover:font-bold text-xs"
              color={activeStep === 0 ? "blue-gray" : "gray"}
            >
              Authentication
            </Typography>
          </div>
        </Step>
      </Stepper>

      {/* this is the display  for all controls like gold loan gold application etc... */}
      <StepDisplay step={currentStep} />
      {/* forms end */}

      <div className="mt-3 flex justify-between">
        <Button
          size="sm"
          onClick={() => {
            handlePrev();
            setCurrentStep((prev) => prev - 1);
          }}
          disabled={isFirstStep}
        >
          Prev
        </Button>

        <Button
          size="sm"
          type="submit"
          onClick={() => {
            handleNext();
            setCurrentStep((prev) => prev + 1);
          }}
          disabled={status}
          //disabled={false}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
