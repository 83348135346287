import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Pagination from "../../components/Pagination";
import DashBoard from "../../components/DashBoard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { AppContext } from "../../context/appContext";
import AlertMessage from "../../components/alert/Alert";

function DPStatement() {
  const today = new Date().toISOString().split("T")[0];
  const [goldLoans, setGoldLoans] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");
  const itemsPerPage = 16;
  const [startDate, setStartDate] = useState(today);
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const { baseurl, comcode, brcode } = useContext(AppContext);
  const [alertOpen, setAlertOpen] = useState(false); // Manage alert visibility
  const [validationError, setValidationError] = useState(""); // Track validation errors

  const TABLE_HEAD = [
    "Loan ID",
    // "Name",
    "Amount",
    "Loan Date",
    "Period",
    "Interest Rcbl",
    "Interest Rcvd",
    "Principle Rcbl",
    "Principle Rcvd",
    "Int Rate",
  ];

  const datakey = [
    "loanid",
    // "name",
    "lonamount",
    "londate",
    "period",
    "intrecievable",
    "intrecieved",
    "princrecievable",
    "princrecieved",
    "intrate",
  ];

  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchGoldLoans(pageNumber);
  };

  const fetchGoldLoans = async (currentPage) => {
    setDate(startDate);
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseurl}accounting/dp_statement/${comcode}/${brcode}/`,
        {},
        {
          params: {
            page: currentPage,
            start_date: startDate,
            typ: "view",
          },
        }
      );
      console.log("Full API Response:", response.data);
      setGoldLoans(response.data.results.data); // Assuming your API response structure
      setError("");
      setTotalItems(response.data.count);
      
      if (goldLoans.length === 0) {
        setAlertOpen(true);
        const alertMessage = `Data Not Found.`;
        setValidationError(alertMessage);
      }
      else{
        setAlertOpen(false);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response && error.response.status === 404) {
        setError(error.response.data.errors || "Data not found.");
        setAlertOpen(true);
        const alertMessage = `Data Not Found.`;
        setValidationError(alertMessage);
      } else {
        setError("An error occurred while fetching data.");
        setAlertOpen(true);
        const alertMessage = `Data Not found.`;
        setValidationError(alertMessage);
      }
    }
    setLoading(false);
  };

  const fetchAndDownload = () => {
    if (date) {
      axios
        .post(
          `${baseurl}accounting/dp_statement/${comcode}/${brcode}/`,
          {},

          {
            params: {
              page: currentPage,
              start_date: date,
              type: "dwnld",
            },
          }
        )
        .then((response) => {
          const address = response.data.address;
          downloadPDF(
            date,
            response.data.data,
            address.cname,
            address.bname,
            address.address,
            address.email,
            address.phone
          );
          setError("");
        })
        .catch((err) => {
          console.error(err);
          alert("Error fetching data.");
        });
    }
  };

  const fetchAndDownloadExcel = () => {
    if (date) {
      axios
        .post(
          `${baseurl}accounting/dp_statement/${comcode}/${brcode}/`,
          {},
          {
            params: {
              page: currentPage,
              start_date: date,
              type: "dwnld",
            },
          }
        )
        .then((response) => {
          const address = response.data.address;
          downloadExcel(
            date,
            response.data.data,
            address.cname,
            address.bname,
            address.address,
            address.email,
            address.phone
          );
          setError("");
        })
        .catch((err) => {
          console.error(err);
          alert("Error fetching data.");
        });
    }
  };

  const downloadPDF = (
    date,
    goldloans,
    cname,
    bname,
    address,
    email,
    phone
  ) => {
    if (!goldloans.length) {
      alert("No data available for download.");
      return;
    }

    const doc = new jsPDF();
    const formatDate = (date) => {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    };

    const centerText = (doc, text, y) => {
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextWidth(text);
      const x = (pageWidth - textWidth) / 2;
      doc.text(text, x, y);
    };

    doc.setFontSize(16);
    centerText(doc, cname, 15);
    doc.setFontSize(12);
    centerText(doc, address, 20);
    doc.setFontSize(12);
    centerText(doc, bname, 25);
    const emph = `${email} | ${phone}`;
    centerText(doc, emph, 30);
    doc.setFontSize(14);
    centerText(doc, `DP Statement (${formatDate(date)})`, 35);
    doc.setFontSize(12);

    const rows = goldloans.map((goldloan) =>
      datakey.map((key) => goldloan[key] || "0")
    );

    doc.autoTable({
      head: [TABLE_HEAD],
      body: rows,
      startY: 45,
      theme: "striped",
      styles: { fontSize: 10 },
      columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 30 } },
      didDrawPage: (data) => {
        doc.setFontSize(10);
        doc.text(
          `Page ${data.pageNumber}`,
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        );
      },
    });

    const fileName = `DP Statement (${formatDate(date)}).pdf`;
    doc.save(fileName);
  };
  const downloadExcel = async (
    date,
    goldloans,
    cname,
    bname,
    address,
    email,
    phone
  ) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("DP Statement");

    // Function to format date
    const formatDate = (date) => {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    };

    // Header Rows
    worksheet.addRow([cname]).font = { bold: true, size: 16, name: "Arial" }; // Company name
    worksheet.addRow([address]); // Address row
    worksheet.addRow([bname]).font = { size: 14, name: "Arial" }; // Branch name
    worksheet.addRow([`${email} | ${phone}`]); // Email and Phone
    worksheet.addRow([`DP Statement (${formatDate(date)})`]).font = {
      size: 14,
      name: "Arial",
    }; // Statement title
    worksheet.addRow([]); // Spacer row

    // Table Header Row
    const headerRow = worksheet.addRow(TABLE_HEAD);
    headerRow.eachCell((cell) => {
      // Set header font size, bold, and background color
      cell.font = { bold: true, size: 14, name: "Arial" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" }, // Light gray background
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Data Rows
    goldloans.forEach((goldloan) => {
      const row = worksheet.addRow(datakey.map((key) => goldloan[key] || "0"));
      // Apply default font size for data rows
      row.font = { size: 12, name: "Arial" };
    });

    // Auto-fit Columns based on max length
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        if (cell.value) {
          const cellLength = cell.value.toString().length;
          if (cellLength > maxLength) {
            maxLength = cellLength;
          }
        }
      });
      column.width = maxLength + 2; // Add padding for better readability
    });

    // Save File
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `DP Statement ${formatDate(date)}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <DashBoard />

      <div className="container mx-auto px-4 py-8 pt-20">
        <AlertMessage
          open={alertOpen}
          setOpen={setAlertOpen}
          message={validationError || error}
          color={validationError || error ? "red" : "green"}
        />
        <h2 className="text-2xl font-bold mb-4 text-center">DP Statement</h2>

        <div className="overflow-x-auto">
          <div className="flex gap-2">
            <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
              <label className="mb-1 text-sm">Date:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
                className="border rounded px-2 border-black w-full"
              />
            </div>

            <div className="flex flex-wrap w-full mt-5">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  fetchGoldLoans(currentPage);
                }}
                type="submit"
                className="bg-gray-500 text-white px-4 py-1 h-8 rounded-md hover:bg-gray-600 w-full sm:w-auto"
              >
                Submit
              </button>
              &nbsp;
              <button
                type="button"
                className="bg-gray-500 text-white px-4 py-1 h-8 rounded-md hover:bg-gray-600 w-full sm:w-auto"
                onClick={fetchAndDownload}
                disabled={goldLoans.length === 0}
              >
                Download PDF
              </button>
              &nbsp;
              <button
                type="button"
                className="bg-gray-500 text-white px-4 py-1 h-8 rounded-md hover:bg-gray-600 w-full sm:w-auto"
                onClick={fetchAndDownloadExcel}
                disabled={goldLoans.length === 0}
              >
                Download Excel
              </button>
            </div>
          </div>
          {loading && <div className="spinner"></div>}

          <table className="min-w-full bg-white border-gray-200 shadow-sm rounded-lg overflow-hidden">
            <thead className="bg-gray-100">
              <tr>
                <td className="px-4 py-1 text-left">Loan Id</td>
                <td className="px-4 py-1 text-left">Name</td>
                <td className="px-4 py-1 text-left">Amount</td>
                <td className="px-4 py-1 text-left">Loan Date</td>
                <td className="px-4 py-1 text-left">Period</td>
                <td className="px-4 py-1 text-left">Interest Rcbl</td>
                <td className="px-4 py-1 text-left">Interest Rcvd</td>
                <td className="px-4 py-1 text-left">Principle Rcbl</td>
                <td className="px-4 py-1 text-left">Principle Rcvd</td>
                <td className="px-4 py-1 text-left">Int Rate</td>
              </tr>
            </thead>
            <p>{error}</p>
            <tbody>
              {goldLoans.map((goldLoan, index) => (
                <tr key={index} className="text-sm border-b border-gray-200">
                  <td className="px-4 ">{goldLoan.loanid}</td>
                  <td className="px-4 ">{goldLoan.name}</td>
                  <td className="px-4 ">{goldLoan.lonamount}</td>
                  <td className="px-4 ">{goldLoan.londate}</td>
                  <td className="px-4 ">{goldLoan.period}</td>
                  <td className="px-4 ">{goldLoan.intrecievable}</td>
                  <td className="px-4 ">{goldLoan.intrecieved}</td>
                  <td className="px-4 ">{goldLoan.princrecievable}</td>
                  <td className="px-4 ">{goldLoan.princrecieved}</td>
                  <td className="px-4 ">{goldLoan.intrate}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            activePage={currentPage}
            setActivePage={handleSetActivePage}
            fetchdata={fetchGoldLoans}
          />
        </div>
      </div>
    </>
  );
}

export default DPStatement;
