// LOAN REPAY LEFT

import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LoanCloseContext } from "./LoanCloseContext";

function LoanCloseAmount(props) {
  let { loan, sajdata, setData, total, setTotal } =
    useContext(LoanCloseContext);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const classes = "form-control mt-1 flex lg:flex-row flex-col justify-between";

  const onSubmit = (data) => {
    console.log(data);
  };
  //   useEffect(()=>{

  //       for(const [key, value] of Object.entries(loan)){

  //                 setTotal(key, value, { shouldValidate: true })
  //             }
  //             setTotal('total', loan.amount + loan.dc + loan.gst + loan.interest + loan.otc +loan.notice)

  //   },[])
  useEffect(() => {
    // Calculate the total whenever the loan object changes
    const newTotal =
      loan.amount + loan.dc + loan.gst + loan.interest + loan.otc + loan.notice;
    setTotal(newTotal);
  }, [loan]);

  return (
    <div>
      <form className="border shadow-2xl p-4" onSubmit={handleSubmit(onSubmit)}>
        <div className={classes}>
          <label className="block text-gray-700 text-sm ">Amount</label>
          <input
            type="text"
            name="balamount"
            className="text-right"
            // defaultValue={0}
            value={sajdata && sajdata.total_values.balamount}
            //  label="Loan Amount"
            readOnly
          />
        </div>
        <div className={classes}>
          <label className="block text-gray-700 text-sm ">Interest</label>
          <input
            type="text"
            name="intrest"
            className="text-right"
            // defaultValue={0}
            value={sajdata && sajdata.total_values.intrestamt}
            //  label="Loan Amount"
            readOnly
          />
        </div>
        <div className={classes}>
          <label className="block text-gray-700 text-sm ">Penal Charges</label>
          <input
            type="text"
            name="othercharge"
            className="text-right"
            // defaultValue={0}
            value={sajdata && sajdata.total_values.othercharge}
            //  label="Loan Amount"
            readOnly
          />
        </div>
        <div className={classes}>
          <label className="block text-gray-700 text-sm ">GST</label>
          <input
            type="text"
            name="Servicecharge"
            className="text-right"
            // defaultValue={0}
            value={sajdata && sajdata.total_values.Servicecharge}
            //  label="Loan Amount"
            readOnly
          />
        </div>
        <div className={classes}>
          <label className="block text-gray-700 text-sm ">
            Notice Charges and Other Charges
          </label>
          <input
            type="text"
            name="notice"
            className="text-right"
            // defaultValue={0}
            value={sajdata && sajdata.total_values.notice}
            //  label="Loan Amount"
            readOnly
          />
        </div>
        <div className={classes}>
          <label className="block text-gray-700 text-sm ">
            Documentation Charges
          </label>
          <input
            type="text"
            name="documentation"
            className="text-right"
            // defaultValue={0}
            value={sajdata && sajdata.total_values.documentation}
            //  label="Loan Amount"
            readOnly
          />
        </div>
        <div className={classes}>
          <label className="block text-black  text-sm font-bold">Total</label>
          <input
            type="text"
            name="total"
            className="text-right font-bold "
            value={total}
          />
        </div>
      </form>
    </div>
  );
}

export default LoanCloseAmount;
