import React, { useState, useEffect, useRef, useContext } from 'react';
import DashBoard from '../../components/DashBoard';
import imageSrc from '../../components/image/logo.png'
import PdfButton from '../../components/PdfButton';
import { useLocation } from 'react-router';
import { AppContext } from '../../context/appContext';


function LoanMain() {
    const location = useLocation();
    const { state } = location;
    const initialLoanId = state?.loanid || '';
    const{baseurl,comcode, brcode}= useContext(AppContext)
    
    const [loanId, setLoanId] = useState(initialLoanId); // Manage input value
    const [loanDetails, setLoanDetails] = useState(null);
    const [loanPerticular, setLoanPerticular] = useState(null);
    const [branchDetails, setBranchDetails] = useState(null);
    const [companyDetails, setCompanyDetails] = useState(null);
    const [approver, setApprover] = useState();

   
    // Fetch loan details when loanId changes
    useEffect(() => {
        const fetchLoanDetails = async () => {
            if (!loanId.trim()) return; // Skip fetch if loanId is empty or only whitespace

            try {
                const response = await fetch(`${baseurl}goldloan/fetch-loan/${comcode}/${brcode}/?loanid=${encodeURIComponent(loanId)}`, {
                    method: 'GET',
                    mode: 'cors',
                });
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();

                setLoanDetails(data.data);
                
                setBranchDetails(data.data[0].branch_details)
                setCompanyDetails(data.data[0].company_details)
            } catch (error) {
                console.error('Error fetching loan details:', error);
                setLoanDetails(null);
            }
        };

        fetchLoanDetails();
    }, [loanId]);

    // Fetch loan particulars when loanId changes
    useEffect(() => {
        const fetchLoanPerticulars = async () => {
            if (!loanId.trim()) return; // Skip fetch if loanId is empty or only whitespace

            try {
                const response = await fetch(`${baseurl}goldloan/fetch-golddetails/${comcode}/${brcode}/?loanid=${encodeURIComponent(loanId)}`);
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setLoanPerticular(data.data);
                setApprover(data.approver)

            } catch (error) {
                console.error('Error fetching loan particulars:', error);
                setLoanPerticular(null);
            }
        };

        fetchLoanPerticulars();
    }, [loanId]);

    // Handle input change
    const handleInputChange = (event) => {
        setLoanId(event.target.value);
    };

 

    // Total calculation function
    const calculateTotal = (propertyName) => {
        if (!Array.isArray(loanPerticular) || loanPerticular.length === 0) return 0;

        return loanPerticular.reduce((total, item) => {
            const value = parseFloat(item[propertyName]);
            return total + (isNaN(value) ? 0 : value);
        }, 0).toFixed(3);
    };
    const maskMobileNumber = (mobileNumber) => {
        if (mobileNumber && mobileNumber.length >= 6) {
          return 'XXXXXX' + mobileNumber.slice(6);
        }
        return mobileNumber;  // In case the number is less than 6 digits.
      };


    return (
        <>
            <DashBoard />
            <div className="w-11/12 mr-auto bg-white ml-auto font-medium" style={{ fontFamily: 'sans-serif' }}>
                <div className="flex w-full shrink-0 gap-2 justify-around">
                    <div className="w-full md:w-72 mt-20">
                        <input
                            className='static px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500 transition-all duration-300 w-full'
                            placeholder='Search'
                            value={loanId}
                            onChange={handleInputChange} // Update state on change
                        />
                    </div>
                </div>

                {/* Loan Details Section */}
                {loanDetails && (
                    <PdfButton filename="loan-application.pdf" loanid={loanId}>
                        <div className="page " id="page1">
                            <div className="w-full" >

                                <div className="flex flex-row space-x-reverse">
                                    <div>
                                        <p className="text-sm ">From</p>
                                        <p className="text-sm ml-10">{loanDetails[0].full_name}<br />{loanDetails[0].address}<br />{loanDetails[0].address2}</p>
                                        <p className="text-sm">To</p>
                                        <p className="text-sm ml-10">The Manager<br />{branchDetails.brname} <br />fax: 080-22210559 Banglore</p>
                                        <p className="text-sm">Dear Sir,</p>
                                        <p className="text-sm ml-10 text-lg font-mono">Please grant me a Loan Rs. {loanDetails[0].amount} on the security of {calculateTotal('calcweight')} grams of Gold Ornaments tendered herewith, the details of which are furnished below.</p>
                                    </div>
{/* image adding area by sajith */}
                                    <div className="flex flex-col items-end w-full">
                                        <p className="justify-end "><span>Date</span>:<span> {loanDetails[0].trdate}</span></p>
                                        <p className="justify-end"><span>{loanDetails[0].loanid}</span></p>
                                        <img
                                            src={loanDetails[0].image}
                                            alt="Customer"
                                            className="w-36 h-36 rounded-md mt-10"
                                        />
                                    </div>
                                </div>
                                <h1 className="text-center font-bold text-lg mt-1 mb-5">Details of ornaments</h1>
                                <div className="w-full">
                                    <table className="text-sm w-full h-32 text-left text-lg">
                                        <thead>
                                            <tr className="bg-gray-100 h-5">
                                                <th className="sm:px-4 py-2">SL.No</th>
                                                <th className="border px-2 sm:px-4 py-2">Items</th>
                                                <th className="border px-2 sm:px-4 py-2">Status</th>
                                                <th className="border px-2 sm:px-4 py-2">Count</th>
                                                <th className="border px-2 sm:px-4 py-2">Gross Weight<br /> in Grams</th>
                                                <th className="border px-2 sm:px-4 py-2">Net Weight<br /> in Grams</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loanPerticular && loanPerticular.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-xs border px-2 sm:px-4 py-2">{item.sl_no}</td>
                                                    <td className="text-sm border px-2 sm:px-4 py-2">{item.item}</td>
                                                    <td className="text-xs border px-2 sm:px-4 py-2">{item.description}</td>
                                                    <td className="text-xs border px-2 sm:px-4 py-2">{item.count}</td>
                                                    <td className="text-xs border px-2 sm:px-4 py-2">{item.weight}</td>
                                                    <td className="text-xs border px-2 sm:px-4 py-2">{item.calcweight}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className="text-xs border px-2 sm:px-4 py-2"></td>
                                                <td className="text-xs border px-2 sm:px-4 py-2"></td>
                                                <td className="text-sm border px-2 sm:px-4 py-2">Total</td>
                                                <td className="text-xs border px-2 sm:px-4 py-2"></td>
                                                <td className="text-xs border px-2 sm:px-4 py-2">{calculateTotal('weight')}</td>
                                                <td className="text-xs border px-2 sm:px-4 py-2">{calculateTotal('calcweight')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="mt-5">Signature</p>
                                <span className="text-sm font-bold ">KYC Collected</span>
                                <span className="text-sm ml-20 font-bold ">Aadhar Verified</span>
                                <table className="border-2 border-gray-500 min-w-full mt-5 bg-white table-fixed">
                                    <tbody>
                                        <tr className="h-10">
                                            <td className="text-sm border-2 border-gray-200 px-4 py-2 w-1/3">
                                                Certified that the Gross, Net weight of the jewellery, valuation rate quality have been verified by me and are correct
                                                <div className="text-sm text-center font-bold">Appraiser (Maker) {approver}</div>
                                            </td>
                                            <td className="text-sm border-2 border-gray-300 px-4 py-2 w-1/3">
                                                Gross Net weight, valuation Rate verified Ornaments taken into Custody
                                                <div className="text-sm text-center mt-3 font-bold">Joint Custodian (Approver)</div>
                                            </td>
                                            <td className="text-sm border-2 border-gray-300 px-4 py-2 w-1/3">
                                                Loan sanctioned Rs {loanDetails[0].amount}
                                                <div className="text-sm text-center mt-10 font-bold">Manager</div>
                                            </td>
                                        </tr>
                                        <tr className="w-full">
                                            <td className="text-sm text-center w-1/2 border-2 border-gray-300 px-4 py-2" colSpan={2}>
                                                The ornaments specified herein have been returned to the pawner
                                                <div className="text-sm text-center mt-5 font-bold">Joint Custodian</div>
                                            </td>
                                            <td className="text-sm text-center w-1/2 border-2 border-gray-300 px-4 py-2">
                                                Received the ornaments pledged in tact
                                                <div className="text-sm text-center mt-5 font-bold mt-10">Signature of Pawner/Authorised Representatives</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-sm h-20 text-center border-2 border-gray-300 px-4 py-2 w-full" colSpan="2">Particulars of Auction(if any)</td>
                                            <td className="text-sm h-20 text-center border-2 border-gray-300 px-4 py-2 w-full">
                                                Amount Rs {loanDetails[0].amount}
                                                <div className="text-sm text-center mt-3 font-bold text-right mr-10">Signature of officer incharge</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p className="mt-2 mb-5 text-sm">
                                    I state that the maximum value of the pledged items under this pledge will be Rs {loanDetails[0].amount} (Rupees________________________) or the then market value of gold
                                    as on the date of any claim whichever is less.
                                </p>
                                <p className="mt-2 mb-5 text-sm text-right ">
                                    Signature Of Applicant
                                </p>
                                <p className='font-bold text-center'>Details regarding payments/adjustment of surplus, net sale proceeds(if any)</p>
                                <p>.</p>
                                <hr />
                            </div>
                        </div>
                        <div className="page" id="page2">
                            <div className="w-full">
                                <div className='flex justify-between'>
                                    <div>

                                        <p className='font-bold text-sm'>{loanDetails[0].loanid}</p>
                                        <p className=' font-bold text-sm'>Rs.{loanDetails[0].amount}</p>
                                    </div>
                                    <div>
                                        <p className='text-right font-bold text-sm ' ><span> Place: </span><span> {loanDetails[0].city}</span></p>
                                        <p className='text-right font-bold text-sm'><span> Date: </span><span> {loanDetails[0].trdate}</span></p>

                                    </div>
                                </div>
                                <p className='text-sm mt-10'>ON DEMAND I promise to pay M/s. Chemm Finance Ltd or order at Banglore the sum of Rs {loanDetails[0].amount} with interest at the date of 22% per annum(MAX)
                                    or at such revised rates as the company may fix From time to time from the date hereof until payment in full, for value received.
                                </p>
                                <table className="text-sm min-w-full bg-white table-fixed  mb-10 mt-10">
                                    <tr className=" h-20">
                                        <td className="text-sm px-4 py-2 w-1/3">
                                            Name
                                        </td>
                                        <td className="text-sm px-4 py-2 w-1/3">
                                            {loanDetails[0].full_name}<br />
                                            {loanDetails[0].address}<br />
                                            {loanDetails[0].address2}<br />

                                        </td>
                                        <td className="text-center border-2 border-gray-800 px-4 py-2 w-1/3 h-40 text-xl font-bold">
                                            Stamp
                                        </td>
                                    </tr>
                                </table>
                                <p className='text-sm'><span>Mobile : {maskMobileNumber(loanDetails[0].mobile)}</span></p>
                                <hr className='mt-40 border-3 border-gray-800 mb-10' />
                                <h1 className='font-bold text-center'>CONSIDERATION RECEIPT</h1>
                                <p className='text-sm text-right font-bold mt-10'><span> Place: {loanDetails[0].city} </span></p>
                                <p className='text-sm text-right font-bold'><span> Date: </span><span> {loanDetails[0].trdate}</span></p>
                                <p className='text-sm font-bold'>{loanDetails[0].loanid}</p>
                                <p className='text-sm font-bold'>Rs. {loanDetails[0].amount}</p>
                                <p className='text-sm'>I/We {loanDetails[0].full_name} Acknowledge receipt of the dull consideration of {loanDetails[0].amount} being the proceeds of the On Demand Pronotr executed
                                    bt me/us this day in favor of CHEMM FINANCE LTD. fax: 080-22210559
                                </p>
                                <div className='flex justify-between'>
                                    <p className='text-sm font-bold mt-20 mb-20 '>WITNESS</p>
                                    <p className='text-sm font-bold mt-20 mb-20'>SIGNATURE</p>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div className="page" id="page3">
                            <div className="w-full">

                                <div style={{ padding: '20px', backgroundColor: 'white', width: '100%', fontFamily: 'TimesRoman, Times New Roman, serif' }}>
                                    <div className='flex '>
                                        <div>
                                            <img
                                                src={imageSrc}
                                                alt="Chemmfinance"
                                                className="w-36 h-36 rounded-md "
                                            />
                                        </div>
                                        <div className='ml-20 text-center'>
                                            <p className='text-2xl'>{companyDetails.comname}</p>
                                            <p>{companyDetails.address}</p>
                                            <p>Tel: {companyDetails.phone}</p>
                                            <p>Regd  : {branchDetails.address}</p>
                                            <p>Email: {branchDetails.email}</p>
                                            <p>GST : U85110KA1993PLC014599</p>
                                        </div>

                                    </div>
                                    <p className='mt-10'>To</p>
                                    <h3 className='ml-5 font-bold text-lg'>{loanDetails[0].full_name},</h3>
                                    <p className='text-sm ml-10'>A Loan of Rs {loanDetails[0].amount} is Sanctioned to you against pledge of gold ornaments on following terms</p>
                                    <div className='flex justify-center'>
                                        <div className='flex justify-center w-10/12 mt-10'>
                                            <table className="text-sm bg-white w-full text-center">
                                                <tbody>
                                                    <tr className="">
                                                        <td className="border px-4 py-2">Gold Loan No.</td>
                                                        <td className="border px-4 py-2">{loanDetails[0].loanid}</td>
                                                        <td className="border px-4 py-2" rowSpan="3">
                                                            <div className="h-full flex flex-col justify-center">
                                                                <div>Rate of interest</div>
                                                                <div>Compounded monthly</div>
                                                                <div>Up to 30 days - 18%</div>
                                                                <div>31D to 60D - 20%</div>
                                                                <div>61D to 90D - 21%</div>
                                                                <div>91D to 365D - 22%</div>
                                                                <div>Penalty Charges: Max 3% (Applicable beyond 365 days)</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td className="border px-4 py-2 font-medium">Date of Pledge</td>
                                                        <td className="border px-4 py-2">{loanDetails[0].trdate}</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="border px-4 py-2 font-medium">Period</td>
                                                        <td className="border px-4 py-2">{loanDetails[0].fk_scheme.duration} Days</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <p className='text-center mt-5 mb-5'>Processing fees: 0.10% with Maximum Rs 200</p>
                                    <h1 className='text-center font-bold text-lg mb-3'>Details of ornaments</h1>
                                    <div className="w-full">
                                        <table className="text-sm w-full min-w-max text-left text-lg">
                                            <thead>
                                                <tr className="bg-gray-100">
                                                    <th className="border px-2 sm:px-4 py-2">SL.No</th>
                                                    <th className="border px-2 sm:px-4 py-2">Items</th>
                                                    <th className="border px-2 sm:px-4 py-2">Status</th>
                                                    <th className="border px-2 sm:px-4 py-2">Count</th>
                                                    <th className="border px-2 sm:px-4 py-2">Gross Weight in Grams</th>
                                                    <th className="border px-2 sm:px-4 py-2">Net Weight in Grams</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loanPerticular && loanPerticular.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-xs border px-2 sm:px-4 py-2">{item.sl_no}</td>
                                                        <td className="text-xs border px-2 sm:px-4 py-2">{item.item}</td>
                                                        <td className="text-xs border px-2 sm:px-4 py-2">{item.description}</td>
                                                        <td className="text-xs border px-2 sm:px-4 py-2">{item.count}</td>
                                                        <td className="text-xs border px-2 sm:px-4 py-2">{item.weight}</td>
                                                        <td className="text-xs border px-2 sm:px-4 py-2">{item.calcweight}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td className="text-xs border px-2 sm:px-4 py-2"></td>
                                                    <td className="text-xs border px-2 sm:px-4 py-2"></td>
                                                    <td className="text-xs border px-2 sm:px-4 py-2">Total</td>
                                                    <td className="text-xs border px-2 sm:px-4 py-2">{calculateTotal('count')}</td>
                                                    <td className="text-xs border px-2 sm:px-4 py-2">{calculateTotal('weight')}</td>
                                                    <td className="text-xs border px-2 sm:px-4 py-2">{calculateTotal('calcweight')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='flex justify-end pt-3'>
                                        Accepted the terms of sanction
                                    </div>
                                    <div className='flex justify-between pt-5 ' style={{marginRight: "135px"}}>
                                        <div>
                                            <p>Manager</p>
                                        </div>
                                        <div>
                                            <p>Borrower</p>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div className="page" id="page4">
                            <div className="w-full">

                                <div className="p-2 bg-white">
                                    <h1 className="text-xl font-bold text-center mb-10 ">TERMS & CONDITIONS</h1>
                                    <ol className="text-sm text-justify">
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>1. </span>
                                                If full repayment is not made within 12 months from the date hereof or within such period as demanded by the company, Penal Charges up to 3% p.a from the date of overdue above the applicable interest rate will be levied and the company shall have the right to sell the ornaments at the risk of the borrower either by public auction or by private arrangement at any time after 2 weeks from the date of notice of sale, to the borrower and adjust from the net proceeds of such sale, all amounts due to the company in respect of the loan. If there is any surplus on such sale the company shall have the right to and shall appropriate such surplus towards any other liability by the borrower by himself or jointly with others on any account whatsoever to the company in any of its office.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>2. </span>
                                                The company has the right to retain the security offered to this loan against any other liabilities due to the company as borrower or guarantor whether such liability has been demanded or not.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>3. </span>
                                                The company has the right to assign or transfer the rights under the agreement and all other documents executed by the borrower in favor of the company and to obtain necessary advances from the Banks/Financial Institutions at any time. The address for all communication shall be the one furnished in the application form if not intimated to the company regarding any subsequent change of address in writing and under acknowledgment from the company.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>4. </span>
                                                The jewellery pledged is the absolute property of the borrower, no other person is having any right, interest or claim over the same and the borrower has absolute right to pledge them. In the event of any defect in the title to the jewellery, the borrower shall indemnify the company of all harm, costs and consequences.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-start"> {/* Wrapper for list item */}
                                                <span className='font-bold'>5. </span>The company is authorized to take loan or advance against repledge or otherwise of the jewellery pledged herein.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>6. </span>
                                                Notwithstanding anything contained in Para-1 above regarding the tenure of the Loan, the Company reserves the right to sell the ornaments either by the public auction or by private arrangement at any point of time or in the immediate future, even before the expiry of 12 months if the company is convinced that the market price maximum realizable amount by sale of items has come down below or equal to the total receivable amount from the borrower by way of principal, interest and other charges due thereon, after serving a registered notice to the borrower.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>7. </span>
                                                The borrower shall bear, pay and reimburse all charges relating to administration charges, including stamp duty and GST (of any description as may be levied from time to time by Government or other authority) and all other costs and expenses whatsoever in connection with (a) application for and the grant and repayment of loan (b) recovery and realization of the loan together with interests (c) enforcement of security: (d) clearance of arrears of all taxes and any other charges and levies of the Government in respect of Security and insuring the Security.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>8. </span>
                                                In the event of loss of pledged ornaments, the liability of the company is limited to replacing the lost ornament with equal net weight disclosed in the pawn ticket.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>9. </span>
                                                Broken/damaged/defective jewellery /ornaments are not accepted as security for the loan unless the borrower makes a specific request to accept the same duly authenticating the damage/defect. In all such cases the company doesn’t own any responsibility.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>10. </span>
                                                There is always a possibility of marginal weight loss (0 to 500mgs) due to storage/humidity etc which shall not be disputed by the borrower (Pawner).
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>11. </span>
                                                Holder of this loan slip is presumed to be the person entitled to redeem the pledge. Borrower should bring this loan slip invariably at the time of releasing the jewellery/ornaments.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>12. </span>
                                                Minimum 15 days interest will be charged on the loan even if it be repaid within the said period, besides rental and insurance fee at such other rates the company may fix from time to time.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex justify-between"> {/* Wrapper for list item */}
                                                <span className='font-bold'>13. </span>
                                                Notwithstanding anything stated above, the maximum value of the pledged items under the pledge will be as shown in the application form duly signed by me, or the then market value of gold as on the date of any claim whichever is less.
                                            </div>
                                        </li>
                                    </ol>
                                    <div className="mt-4">
                                        <p className='flex justify-end font-bold' >I read, Understood and agreed to the above terms & conditions</p>
                                        <div className="flex justify-end pt-5" style={{marginRight:"250px"}}>
                                        <p className='font-bold' >BORROWER</p>
                                        </div>
                                        <p>Date: {loanDetails[0].trdate}</p>
                                        <p>Place: {branchDetails.address}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* Additional loan details rendering */}
                    </PdfButton>
                )}

                {/* Optional: Loading indicator */}
                {!loanDetails && (
                    <div className="mt-5 p-4 border border-gray-300 rounded-lg">
                        <p>Enter a valid Loan Id to get more Details...</p>
                    </div>
                )}
            </div>
        </>
    );
}

export default LoanMain;
