import React, { useContext, useState } from "react";
import {
  IsDeviceConnected,
  CaptureFinger,
  GetConnectedDeviceList,
  InitDevice,
  GetMorFinAuthInfo,
  GetTemplate,
  VerifyFinger,
} from "../pages/goldloan/morfinauth";
import { AppContext } from "../context/appContext";
import axios from "axios";
import { Button } from "@material-tailwind/react";

function ApprovalModal({ isVisible, onClose, onConfirm }) {
  const [userId, setUserId] = useState("");
  const { baseurl, ucode } = useContext(AppContext);

  const [fin, setFin] = useState("");
  const [fpMessage, setFpMessage] = useState("");
  const [verified, setVerified] = useState(false);
  const [verifyProcess, setVerifyProcess] = useState(false);

  const verifyUser = async () => {
    setVerifyProcess(true);
    let list = GetConnectedDeviceList();
   console.log(list, list.data.ErrorDescription !== "Connected Device :MFS500")
    if(list.data.ErrorDescription !== "Connected Device :MFS500"){
      onClose();
      resetModal();
      alert('Please connect recommended Fingerprint Scanner')
      return
    }
    let connectedDvc = list.data.ErrorDescription.split(":");
    connectedDvc = connectedDvc[1].split(",")[0];
    console.log(connectedDvc)
    let clientKey = "";
    GetMorFinAuthInfo(connectedDvc, clientKey);

    let result = CaptureFinger(60, 10);

    let template = GetTemplate(0);

    let fp;
    let fp_verify = false; // for checking the user is verified or not
    try{

      const res1 = await axios.get(`${baseurl}user/userfp_by_id/${userId}`);
      console.log(res1)
  
      // fingerprint list of user
      fp = await res1.data.data;
    }catch(error){
      // if(error.response.data)
      console.log('errpr', error)
      if(error.response.data.status_code === 404){
        console.log('page no ', error.response.data.errors)
        setFpMessage(error.response.data.errors
          
        )
      }
      setVerifyProcess(false);

      return
    }
    

    if (result.httpStaus) {
      setFin(CaptureFinger(60, 10));
    }
  

    // verifying fingerprint
    for (let i = 0; i < fp.length; i++) {
      // device built-in function to verify fingerprint
      let res = VerifyFinger(template.data.ImgData, fp[i].fp, 0);

      if (res.httpStaus) {
        // breaks the loop when one of the fingerprint matches
        if (res.data.Status) {
          fp_verify = true;
          setVerified(true);
          setVerifyProcess(false);

          break;
        } else {
          if (res.data.ErrorCode != "0") {
          } else {
            fp_verify = false;
            setVerifyProcess(false);

            setVerified(false);
          }
        }
      } else {
        // setVerify(true);
        setVerifyProcess(false);
        // setVerifyMessage(res.err);
      }
    }
    if (!fp_verify) {
      setFpMessage("Finger not matched");
      setVerifyProcess(false)
    }
  };
  const resetModal = () => {
    setVerified(false);
    setVerifyProcess(false)
    setFin("");
    setFpMessage("");
    setUserId("");
  };
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex sm:flex sm:items-start">
              {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                            </div> */}
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Are you sure you want to approve?
                </h3>
              </div>
            </div>
            <div className="w-full my-2 flex justify-center items-center">
              <input
                type="text"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                placeholder="User Id"
                style={{ border: "1px solid" }}
              />
              <Button size="sm" disabled={userId.length>0? false: true} className="ms-2" loading={verifyProcess} onClick={() => verifyUser()}>
               {!verifyProcess? 'verify':"verifying"}
              </Button>
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="size-20 flex justify-center items-center border border-black">
                {fin && fin.data.ErrorCode === "0" ? (
                  <img
                    className="size-16"
                    src={`data:image/bmp;base64,${fin.data.BitmapData}`}
                  />
                ) : (
                  <span className="text-sm">fingerprint</span>
                )}
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              {verified && (
                <span style={{ color: "green" }}>User Verified</span>
              )}
              {fpMessage && <span style={{ color: "red" }}>{fpMessage}</span>}
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <Button
              onClick={() => {
                onConfirm(userId);
                resetModal();
              }}
              disabled={!verified}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                onClose();
                resetModal();
              }}
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApprovalModal;
