import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router";
import { AppContext } from "../context/appContext";
import axios from "axios";

function PrivateRoute({ children, page }) {
  let {
    authToken,
    user,
    brcode,
    comcode,
    gcode,
    ucode,
    username,
    baseurl,
    permissions,
    setPermissions,
  } = useContext(AppContext);

  // let exists = user && user.page_permissions
  //   ? user.page_permissions.some((obj) => obj.pagenumber === page)
  //   : false;
  console.log(
    "private route",
    !authToken,
    authToken,
    user,
    ucode,
    brcode,
    comcode
  );

  let exists = permissions ? permissions.some((obj) => obj === page) : false;
  if (!authToken || !brcode || !ucode || !comcode || !exists && page) {
    // if (!user || !authToken) {

    return <Navigate to="/homepage" />;
  }
  // else{
  //   if(!exists){
  //     return <Navigate to='/dashboard' />
  // }
  // }

  return children;
}

export default PrivateRoute;
