import React, { useContext, useState } from "react";
import axios from "axios";
import { AppContext } from "../../context/appContext";
import DashBoard from "../../components/DashBoard";

const LoanCheck = () => {
  const [goldLoanNo, setGoldLoanNo] = useState("");
  const { baseurl, comcode, brcode, sidebarToggle } = useContext(AppContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let typ = "O";
      const response = await axios.post(`${baseurl}goldloan/checkid/${comcode}/${brcode}/${goldLoanNo}/${typ}/`);
      if (response.data.status === "success") {
        alert("Loan Id: " + response.data.loanid);
      } else {
        alert("Error: Loan Not Found or Invalid Credentials");
      }
      setGoldLoanNo("");
    } catch (error) {
      console.error("Error submitting form", error);
      alert("An error occurred. Please try again.");
    }
  };

  const checkid = async (e) => {
    e.preventDefault();

    try {
      let typ = "N";
      const response = await axios.post(`${baseurl}goldloan/checkid/${comcode}/${brcode}/${goldLoanNo}/${typ}/`);
      if (response.data.status === "success") {
        alert("Loan Id: " + response.data.loanid);
      } else {
        alert("Error: Loan Not Found or Invalid Credentials");
      }
      setGoldLoanNo("");
    } catch (error) {
      console.error("Error submitting form", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div>
      <DashBoard />
      <div className={`${!sidebarToggle ? "ml-64 duration-500" : "ml-0 duration-500"}`}>
        <div className="pt-20">
          <div className="p-5 max-w-md mx-auto bg-gray-100 rounded-lg shadow-lg">
            <h2 className="text-center text-gray-800 text-xl font-semibold mb-5">
              Gold Loan Check
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="goldLoanNo"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Gold Loan No:
                </label>
                <input
                  type="text"
                  id="goldLoanNo"
                  name="goldLoanNo"
                  value={goldLoanNo}
                  onChange={(e) => setGoldLoanNo(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-gray-400"
                  placeholder="Enter Gold Loan Number"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-3 bg-gray-600 text-white font-bold rounded-md hover:bg-gray-700 transition-colors"
              >
                Get New Loan ID
              </button>

              <div className="my-4"></div>

              <button
                onClick={checkid}
                className="w-full py-3 bg-gray-600 text-white font-bold rounded-md hover:bg-gray-700 transition-colors"
              >
                Get Old Gold Loan Number
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanCheck;
