import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import "./voucherprint.css";
import { AppContext } from "../../context/appContext";
import { useLocation } from "react-router";

function PaymentVoucher() {
  const location = useLocation();
  const data = location.state;
  const [voucherNo, setVoucherNo] = useState("");
  const [totalDebit, setTotalDebit] = useState(null);
  const [address, setAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searched, setSearched] = useState(false);
  const [daybooks, setDaybooks] = useState(false);
  const [searchSuccessful, setSearchSuccessful] = useState(false);
  const { baseurl, comcode, brcode } = useContext(AppContext);

  const handleSearch = async (loanId) => {
    if (!loanId) {
      return;
    }

    setLoading(true);
    setError(null);
    setSearched(true);
    setSearchSuccessful(false); // Reset search successful state

    try {
      const response = await axios.get(
        `${baseurl}receiptpayment/payment-voucher/${comcode}/${brcode}/${voucherNo}/`
      );
      console.log("API Response:", response.data); // Log the full response for debugging

      setDaybooks(response.data.data);
      setAddress(response.data.address);
      setTotalDebit(response.data.data.total_debit);
      setSearchSuccessful(true); // Mark search as successful
    } catch (err) {
      console.error("API Error:", err); // Log error for debugging
      setError(err.message);
      setSearchSuccessful(false); // Mark search as unsuccessful
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    data && setVoucherNo(data.voucher_no);
    handleSearch(voucherNo);
  }, []);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div
      className="w-full flex justify-center items-start"
      style={{
        fontFamily: "sans-serif",
        paddingTop: "20px",
        minHeight: "100vh",
      }}
    >
      <div
        className="bg-white font-medium"
        style={{
          width: "80%", // Adjust the content width (can be modified to fit your design)
          maxWidth: "800px", // Maximum width of the content
          padding: "20px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Optional: add a shadow for better visual separation
        }}
      >
        <div className="text-center mt-5 no-print">
          <input
            type="text"
            placeholder="Enter Voucher Number"
            value={voucherNo}
            onChange={(e) => setVoucherNo(e.target.value)}
            className="border p-2"
          />
          <button
            onClick={() => handleSearch(voucherNo)}
            className="ml-3 bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
          >
            Search
          </button>
          <button
            onClick={handlePrint}
            className={`ml-3 ${
              searchSuccessful ? "bg-gray-500 hover:bg-gray-700" : "bg-gray-500"
            } text-white px-4 py-2 rounded`}
            disabled={!searchSuccessful}
          >
            Print
          </button>
        </div>

        {searched && (
          <div className="page" id="page3">
            <div className="w-full">
              {loading && <div>Loading...</div>}
              {error && <div>Error: {error}</div>}
              {totalDebit !== null && !loading && !error && (
                <div className="voucher-content">
                  <div
                    className="mr-auto ml-auto"
                    style={{
                      padding: "20px",
                      backgroundColor: "white",
                      fontFamily: "TimesRoman, Times New Roman, serif",
                    }}
                  >
                    <div className="text-center">
                      <p style={{ fontSize: "25px" }}>{address.cname}</p>
                      <p>{address.address}</p>
                      <p>{address.bname}</p>
                      <p>
                        {address.email} | {address.phone}
                      </p>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="flex justify-between mt-3">
                      <span>
                        Date:&nbsp;&nbsp;
                        {daybooks.records[0].trdate}
                      </span>
                      <b>
                        <u>Payment</u>
                      </b>
                      <span>
                        No:&nbsp;&nbsp;
                        {daybooks.records[0].voucher_no}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="w-3/4 mr-auto ml-auto flex justify-between mt-10">
                    <span>
                      Paid to&nbsp;
                      {daybooks.records[0].description.toUpperCase()}
                      &nbsp;an amount of&nbsp; Rs.&nbsp;
                      {totalDebit.toLocaleString()} &nbsp;by&nbsp;
                      {daybooks.records[0].mode}.
                    </span>
                  </div>
                  <div className="w-full flex justify-around mt-10">
                    <span>Signature of Customer</span>
                    <span>For {address.bname.toUpperCase()}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentVoucher;
