export const formatToTudf = (customers, date2) => {
    let month;
    const monthNumber = date2.split("-")[1];
    const year = date2.split("-")[0];
    let date = date2.split("-").reverse().join("");
  
    const monthNames = {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      "10": "October",
      "11": "November",
      "12": "December",
    };
    month = monthNames[monthNumber];
  
    const tudfHeader = `TUDF12NB41910001_DATASUBMISSION     CHEMMFIN        NB${date}XXXXX                         A00000${month} ${year}                                   `;
    const trailer = "TRLR";
  
    const genderMap = {
      female: "1", // Female
      male: "2", // Male
      other: "3", // Other (Transgender)
    };
    const houseMap = {
      ownhouse: "01",
      rentedhouse: "02",
    };
  
    const tudfRecords = customers.map((customer) => {
      const customerName = [customer.fname, customer.mname, customer.lname]
        .filter(Boolean)
        .join(" ");
      const nameLength = customerName.length;
  
      function formatName(name) {
        const maxLength = 26; // Maximum length for each chunk
        const maxTags = 5; // Maximum number of tags (01, 02, 03, 04, 05)
        let formattedName = "";
  
        // Split the name into words
        const words = name.split(/\s+/);
  
        let currentChunk = "";
        let tagIndex = 1; // Start with tag `01`
  
        // Process the name in chunks, with a maximum of 5 tags
        for (let i = 0; i < words.length; i++) {
          const word = words[i];
  
          // Check if the current chunk plus the new word exceeds the max length
          if (currentChunk.length + word.length + 1 <= maxLength) {
            // If not, add the word to the current chunk
            currentChunk += (currentChunk.length === 0 ? "" : " ") + word;
          } else {
            // If it exceeds, finalize the current chunk and move to the next tag
            if (tagIndex <= maxTags) {
              formattedName += `${String(tagIndex).padStart(
                2,
                "0"
              )}${currentChunk.length
                .toString()
                .padStart(2, "0")}${currentChunk}`;
              tagIndex++;
            }
            // Start a new chunk with the current word
            currentChunk = word;
          }
        }
  
        // If there's any remaining content after the last tag, add it
        if (currentChunk.length > 0 && tagIndex <= maxTags) {
          formattedName += `${String(tagIndex).padStart(
            2,
            "0"
          )}${currentChunk.length.toString().padStart(2, "0")}${currentChunk}`;
        }
  
        // If there are remaining characters after the 5th tag, truncate and place them into tag `05`
        if (tagIndex > maxTags && currentChunk.length > 0) {
          formattedName += `05${maxLength
            .toString()
            .padStart(2, "0")}${currentChunk.slice(0, maxLength)}`;
        }
  
        return formattedName;
      }
  
      // Example customer name
  
      // Format the name based on the logic
      const formattedName = formatName(customerName);
  
      // Display the result
  
      const gender = genderMap[customer.gender.toLowerCase()] || "";
      const ownhouse = houseMap[customer.ownhouse.toLowerCase()] || "";
      const pin = customer.pin || "";
      const branchCode = customer.brcode || "";
      const email = customer.email || "";
      const mobile = customer.mob || "";
      const dob = customer.dob ? customer.dob.split("-").reverse().join("") : "";
  
      const state = customer.cusstste || "";
      function splitAddressByWords(address, maxLength) {
        const words = address.split(/\s+/);
        const lines = [];
        let currentLine = "";
  
        words.forEach((word) => {
          if ((currentLine + " " + word).trim().length <= maxLength) {
            currentLine = (currentLine + " " + word).trim();
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        });
  
        if (currentLine) {
          lines.push(currentLine);
        }
  
        return lines.slice(0, 5);
      }
  
      function formatAddressLines(lines) {
        return lines
          .map((line, index) => {
            const sequence = (index + 1).toString().padStart(2, "0");
            const length = line.length.toString().padStart(2, "0");
            return `${sequence}${length}${line}`;
          })
          .join("");
      }
  
      const combinedAddress = [customer.loanaddress || ""]
        .join(" ")
        .replace(/[^a-zA-Z0-9\s/]/g, "")
        .replace(/[\r\n]/g, " ")
        .trim();
      const addressLines = splitAddressByWords(combinedAddress, 40);
      const address = `PA03A01${formatAddressLines(addressLines)}`;
      const aahaarNumber = customer.aadhaar || "";
      const loanid = customer.loanid || "";
      let loanlength = loanid.length;
      if (loanlength >= 0 && loanlength <= 9) {
        loanlength = "0" + loanid.length; // Update loan length after modification
      }
      const trdate = (customer.transaction_date || "")
        .split("-")
        .reverse()
        .join("");
      const reporteddate = (customer.transaction_date || "")
        .split("-")
        .reverse()
        .join("");
  
      const amount = String(customer.amount || ""); // Ensure it’s a string
      let amountlength = amount.length; // Get the length of the amount string
      if (amountlength >= 0 && amountlength <= 9) {
        amountlength = "0" + amountlength.toString(); // Update loan length after modification
      }
  
      const balance = String(customer.amount || "");
      let balancelength = balance.length;
      if (balancelength >= 0 && balancelength <= 9) {
        balancelength = "0" + balancelength.toString(); // Update loan length after modification
      }
      const collateralvalue = String(customer.amount || "");
      let vallength = collateralvalue.length;
      if (vallength >= 0 && vallength <= 9) {
        vallength = "0" + vallength.toString(); // Update loan length after modification
      }
      const intrate = String(customer.interest_rate || "");
      let intlength = intrate.length;
      if (intlength >= 0 && intlength <= 9) {
        intlength = "0" + intlength.toString(); // Update loan length after modification
      }
      
      return `PN03N01${formattedName}0708${dob}0801${gender}ID03I010102060212${aahaarNumber}PT03T010110${mobile}030201${address}0602${state}0706${pin}080202TL04T0010110NB419100010208CHEMMFIN03${loanlength}${loanid}040207050110808${trdate}1108${date}12${amountlength}${amount}13${balancelength}${balance}26020134${vallength}${collateralvalue}35020238${intlength}${intrate}390212ES02**`;
    });
  
    return `${tudfHeader}${tudfRecords.join("")}TRLR`;
  };
  