// LOAN REPAY HEADER

import React, { useContext, useState } from "react";
// import React from 'react';
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import axios from "axios";
import { AppContext } from "../../context/appContext";
import AlertMessage from "../../components/alert/Alert";
import { LoanCloseContext } from "./LoanCloseContext";

function LoanCloseComponent(props) {
  let { baseurl, brcode, comcode, ucode } = useContext(AppContext);
  const { sajdata, setData, setName , setTotal1 ,setFinaldc11,setFinalnotice11,setFinalgst11,view, setView,setAmnt,setRunningTotal,
    setFinalotc11,setFinalinterest11,setFinalamt11, total1,setOpen1,setSuccessMessage1} = useContext(LoanCloseContext);
   

  const [error, setError] = useState(null);
  const { setValue } = useForm();
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();


  const {
    register,
    watch,
    formState: { errors },
  } = useForm();
  const inputValue = watch("loancode");
  const name = "px-5 py-1 flex-col md:flex-row";
  const classes = "flex w-full px-2 mb-4 md:full lg:full justify-between";

  

  const search = async () => {
    setView(true);
    setTotal1('') ;setFinaldc11('') ;setFinalnotice11('') ;setFinalgst11('') ;
        setFinalotc11('') ;setFinalinterest11('') ;setFinalamt11('') ;setAmnt('');setRunningTotal('');
        console.log('sd',total1)
    // const response = await fetch(`${baseurl}goldloan/fetch-loan/${comcode}/${brcode}/?loanid=${encodeURIComponent(loanId)}`,
    await axios
      .get(
        `${baseurl}testapp/test/${comcode}/${brcode}/`,
        //await axios .get(`${baseurl}goldloan/fetch-loan/${comcode}/${brcode}/`,

        {
          params: {
            loanid: inputValue, // Sending the input value as a query parameter
          },
        }
      )

      //   .get(`${baseurl}testapp/test/`)
      .then((response) => {
       //console.log('xxxxxxxxxxxxxxxxxxxxxxx',response.data.Keydata.Status);
        if (response.data.Keydata.Status === 'close')
        {
          // console.log('header', response.data.Keydata)
          setOpen1(true)
          setSuccessMessage1("Loan Closed OR Wrong Loan ID .....!")
          setData("");
        }
        else if (response.data.Keydata.Status === 'Loan not found')
        {
          setOpen1(true)
          setSuccessMessage1(response.data.Keydata.Status)
          setData("");

        }
        else if (response.data.Keydata.Status === 'Date Out of Loan period')
        {
          setOpen1(true)
          setSuccessMessage1(response.data.Keydata.Status)
          setData("");

        }
        
        else if (response.data.Keydata.Status === 'Scheme not found')
          {
            setOpen1(true)
            setSuccessMessage1(response.data.Keydata.Status)
            setData("");
  
          }
        
          else if (response.data.Keydata.Status === 'Interet Not Found')
            {
              setOpen1(true)
              setSuccessMessage1(response.data.Keydata.Status)
              setData("");
    
            }
          else if (response.data.Keydata.Status === 'Date Out of Loan period')
            {
              setOpen1(true)
              setSuccessMessage1(response.data.Keydata.Status)
              setData("");
    
            }
        // else if (typeof response.data.Keydata === 'string' && response.data.Keydata.startsWith('Mismatch'))
        else if (response.data.Keydata.Status === 'Mismatch')
             {
            setOpen1(true)
            setSuccessMessage1(response.data.Keydata.sajerror)
            setData("");
  
          }
      else
        {
        setData(response.data.data); // Set the response data to state
        setName(sajdata.total_values.loanamount);
        setError(null);
        setErrorMessage("");
       }
        
      })
      .catch(function (error) {
        if (error.response) {
          setSuccessMessage("");
          setOpen(true);
          setErrorMessage(error.response.data.error);
          setData("");
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log("Error", error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error.message);
        }
        console.log(error.config);
      });
  };

  return (
    <>
      {/* {successMessage && open && <AlertMessage open={open} setOpen={setOpen} message={successMessage}/>} */}
      {errorMessage && open && (
        <AlertMessage
          open={open}
          color={"red"}
          setOpen={setOpen}
          message={errorMessage}
        />
      )}

      <div className="flex justify-center">
        <div className="w-full border md:w-3/4 mt-4">
          <div className={name}>
            <div className="flex justify-between">
              <div className="flex w-full px-3 mb-4 md:w-full lg:w-full flex-col md:flex-row lg:flex-row">
                <label className="block text-gray-700 md:w-full text-sm ">
                  Loan Code
                </label>
                <Input
                  type="text"
                  name="loancode"
                  // label="Loan Code"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                    minLength: {
                      value: 1,
                      message: "Please enter a minimum of 1 characters",
                    },
                  }}
                  required
                />

                <button
                  onClick={search}
                  className="bg-gray-700 h-8 hover:bg-gray-950 text-white px-2 rounded lg:ml-2"
                >
                  Search
                </button>

                <div className="flex justify-between">
                  <label className="block text-gray-700 text-sm lg:ml-5 mt-1">
                    Name{" "}
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="h-7 pl-5"
                    value={sajdata && sajdata.total_values.name}
                  />
                </div>

                <div className="flex justify-between">
                  <label className="block text-gray-700 text-sm lg:ml-5 mt-1">
                    LoanId{" "}
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="h-7 pl-5"
                    value={sajdata && sajdata.total_values.loanid}
                  />
                </div>
              </div>
            </div>
          </div>
          <section className="flex px-5 py-1 justify-between">
            <div className="w-1/3">
              <div className={classes}>
                <label className="text-gray-700 text-sm mt-1">
                  Loan Amount
                </label>
                <input
                  size="2"
                  type="text"
                  name="loanamount"
                  className="text-right h-7 pl-5"
                  value={sajdata && sajdata.total_values.loanamount}
                  readOnly
                />
              </div>
              <div className={classes}>
                <label className="block text-gray-700 text-sm mt-1">
                  Interest
                </label>
                <input
                  size="2"
                  type="text"
                  name="Interest"
                  className="text-right h-7 pl-5"
                  value={sajdata && sajdata.total_values.intrest}
                />
              </div>
            </div>
            <div className="w-1/3">
              <div className={classes}>
                <label className="block text-gray-700 text-sm mt-1">Days</label>
                <input
                  size="2"
                  type="text"
                  name="Days"
                  className="text-right h-7 pl-5"
                  value={sajdata && sajdata.total_values.Duration}
                />
              </div>
              <div className={classes}>
                <label className="block text-gray-700 text-sm mt-1">
                  Other Charges
                </label>
                <input
                  size="2"
                  type="text"
                  name="OtherCharges"
                  className="text-right h-7 pl-5"
                  value={sajdata && sajdata.total_values.othercharge}
                />
              </div>
            </div>
            <div className="w-1/3">
              <div className={classes}>
                <label className="block text-gray-700 text-sm mt-1">
                  Service Tax
                </label>
                <input
                  size="2"
                  type="text"
                  name="stax"
                  className="text-right h-7 pl-5"
                  value={sajdata && sajdata.total_values.Servicecharge}
                />
              </div>
              <div className={classes}>
                <label className="block text-gray-700 text-sm mt-1">
                  Penal Charge
                </label>
                <input
                  size="2"
                  type="text"
                  name="PenalCharge"
                  className="text-right h-7 pl-5"
                  value={sajdata && sajdata.total_values.PenalCharge}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default LoanCloseComponent;
